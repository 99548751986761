
import moment from "moment/moment";
import LoadingSpinner from "./loading-spinner";
import SaveButton from "./save-button";
import ContactName from "./contacts/contact-name";
import TableActions from "./table-actions.vue";

export default {
    name: "RestrictedModal",
    components: {TableActions, ContactName, SaveButton, LoadingSpinner},
    props: {
        companyId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            uuid: this.generateUUID(),
            tableUpdated: moment().valueOf(),
            isLoading: true,
            isSaving: false,
            isDeleting: false,
            company: null,
            userMarksCompanyAsRestricted: null,
            config: {
                columns: [
                    {
                        headline: "Added By",
                        sort: true,
                    },
                    {
                        headline: "Notes",
                        sort: true,
                        breakpoint: 'all',
                    },
                    {
                        headline: "ATM?",
                        sort: true,
                    },
                    {
                        headline: "Date Added",
                        sort: true,
                    },
                    {
                        headline: "Date Removed",
                        sort: true,
                    },
                    {
                        headline: "Last Change",
                        sort: true,
                    },
                    {
                        headline: "Actions",
                        hideHeadlineBreakpoint: "all",
                        align: "end"
                    },
                ],
                pagination: 10,
                ajaxUrl: '',
            },
        };
    },
    computed: {
        dateAdded: {
            get() {
                if (this.userMarksCompanyAsRestricted && this.userMarksCompanyAsRestricted.dateAdded) {
                    return moment(this.userMarksCompanyAsRestricted.dateAdded).format("YYYY-MM-DD");
                } else {
                    return null;
                }
            },
            set(newValue) {
                this.$set(this.userMarksCompanyAsRestricted, "dateAdded", newValue || null);
            }
        },
        dateRemoved: {
            get() {
                if (this.userMarksCompanyAsRestricted && this.userMarksCompanyAsRestricted.dateRemoved) {
                    return moment(this.userMarksCompanyAsRestricted.dateRemoved).format("YYYY-MM-DD");
                } else {
                    return null;
                }
            },
            set(newValue) {
                this.$set(this.userMarksCompanyAsRestricted, "dateRemoved", newValue || null);
            }
        },
        filters() {
            return {
                company: this.companyId,
            };
        },
    },
    mounted() {
        this.$refs.restrictedModal.addEventListener('shown.bs.modal', () => {
            this.isLoading = true;

            const promises = [this.loadCompany()];

            if (this.isUserWithPermissionRestrictedList) {
                promises.push(this.loadOfCurrentUser());
            }

            Promise.all(promises).then(() => {
                this.$nextTick(() => {
                    this.config.ajaxUrl = '/api/user_marks_company_as_restricteds/list';

                    this.tableUpdated = moment().valueOf();
                });
            })
        });
    },
    methods: {
        loadOfCurrentUser() {
            this.isLoading = true;
            return this.$axios.get(`/api/user_marks_company_as_restricteds/empty/${this.companyId}`).then(response => {
                this.userMarksCompanyAsRestricted = response.data;
                this.isLoading = false;
            });
        },
        loadCompany() {
            return this.$axios.get(`/api/companies/${this.companyId}`).then(response => {
                this.company = response.data;
            });
        },
        validateForm() {
            if (!this.dateAdded) {
                const toastId = this.generateUUID();
                this.addToast({
                    type: "warning",
                    title: "Missing Start Date",
                    message: "The start date of the restriction is missing",
                    id: toastId,
                });
                this.$nextTick(() => {
                    this.toggleToast(toastId);
                });
                return false;
            }

            if (this.dateRemoved) {
                if (this.dateAdded > this.dateRemoved) {
                    const toastId = this.generateUUID();
                    this.addToast({
                        type: "warning",
                        title: "Start date after end date",
                        message: "The start date cannot be after the end date",
                        id: toastId,
                    });
                    this.$nextTick(() => {
                        this.toggleToast(toastId);
                    });
                    return false;
                }
            }

            if (!this.userMarksCompanyAsRestricted.user) {
                const toastId = this.generateUUID();
                this.addToast({
                    type: "warning",
                    title: "Missing User",
                    message: "The user who added this restriction is missing",
                    id: toastId,
                });
                this.$nextTick(() => {
                    this.toggleToast(toastId);
                });
                return false;
            }

            return true;
        },
        saveCurrentRestriction() {
            this.isSaving = true;

            if (!this.validateForm()) {
                this.isSaving = false;
                return;
            }

            const finalUserMarksCompanyAsRestricted = structuredClone(this.userMarksCompanyAsRestricted);

            finalUserMarksCompanyAsRestricted.user = `/api/users/${finalUserMarksCompanyAsRestricted.user.id}`

            if (finalUserMarksCompanyAsRestricted.id) {
                this.$axios.patch(`/api/user_marks_company_as_restricteds/${finalUserMarksCompanyAsRestricted.id}`, finalUserMarksCompanyAsRestricted, {
                    headers: {
                        'Content-Type': 'application/merge-patch+json'
                    }
                }).then(() => {
                    const toastId = this.generateUUID();
                    this.addToast({
                        type: "success",
                        title: "Restriction Updated",
                        message: "The restriction was successfully updated",
                        id: toastId,
                    });
                    this.$nextTick(() => {
                        this.toggleToast(toastId);
                    });
                }).finally(() => {
                    this.isSaving = false;
                    this.$emit("updated");
                });

            } else {
                this.$axios.post('/api/user_marks_company_as_restricteds', finalUserMarksCompanyAsRestricted).then(() => {
                    const toastId = this.generateUUID();
                    this.addToast({
                        type: "success",
                        title: "Restriction Saved",
                        message: "The restriction was successfully saved",
                        id: toastId,
                    });
                    this.$nextTick(() => {
                        this.toggleToast(toastId);
                    });
                }).finally(() => {
                    this.isSaving = false;
                    this.$emit("updated");
                });
            }
        },
        endCurrentRestriction() {
            this.dateRemoved = moment().subtract(1, 'day').format("YYYY-MM-DD");

            this.saveCurrentRestriction();
        },
        editRestriction(id) {
            this.isLoading = true;

            this.$axios.get(`/api/user_marks_company_as_restricteds/${id}`).then(response => {
                this.userMarksCompanyAsRestricted = response.data;
                this.isLoading = false;
            });
        },
        deleteRestriction(id) {
            this.isDeleting = true;

            this.$axios.delete(`/api/user_marks_company_as_restricteds/${id}`).then(() => {
                const toastId = this.generateUUID();
                this.addToast({
                    type: "success",
                    title: "Restriction Deleted",
                    message: "The restriction was successfully deleted",
                    id: toastId,
                });
                this.$nextTick(() => {
                    this.toggleToast(toastId);
                });
            }).finally(() => {
                this.isDeleting = false;

                this.tableUpdated = moment().valueOf();

                if (id === this.userMarksCompanyAsRestricted.id) {
                    this.loadOfCurrentUser();
                }
            });
        },
        closeModal() {
            this.$emit("closed");
        },
    },
}
