
import PCheckIcon from "../p-check-icon";
import ParticipantsAndCompanies from "../interactions/participants-and-companies";
import optionButtonTooltip from "~/mixins/option-button-tooltip";
import contrastColor from "~/mixins/contrast-color";

export default {
    name: "Note",
    components: {ParticipantsAndCompanies, PCheckIcon},
    mixins: [optionButtonTooltip, contrastColor],
    props: {
        note: {
            type: Object,
            required: true,
        },
        showBadge: {
            type: Boolean,
            default: false,
        },
        showEdit: {
            type: Boolean,
            default: true,
        },
        showOpen: {
            type: Boolean,
            default: false,
        },
        showSelect: {
            type: Boolean,
            default: true,
        },
        selected: {
            type: Boolean,
            default: true,
        },
        listInteractionParticipants: {
            type: Boolean,
            default: true
        },
    },
    data() {
        return {
            imageExtensions: ['png', 'gif', 'jpg', 'jpeg'],
            selfSelected: false,
        }
    },
    computed: {
        noteCardClasses() {
            const classes = {};

            if (this.note) {
                if (this.note.noteTypeTitle) {
                    classes['note-type-' + this.note.noteTypeTitleSlug] = true;
                } else {
                    classes['note-type-unknown'] = true;
                }

                if (this.note.important) {
                    if (this.note.noteTypeTitleSlug === 'feedback') {
                        classes['feedback-important'] = true;
                    } else {
                        classes['note-important'] = true;
                    }
                }
            }

            return classes;
        },
        canEdit() {
            return this.userInfo && this.userInfo.id && this.note.user.id === this.userInfo.id || !this.note.private && this.note.othersCanEdit;
        },
        primaryColor() {
            return this.note.company && this.note.company.primaryLogoColor ? this.note.company.primaryLogoColor : '#333'
        },
        contrastColor() {
            return this.note.company && this.note.company.primaryLogoColor ? this.getContrastYIQ(this.note.company.primaryLogoColor) : this.getContrastYIQ('#333')
        },
        images() {
            return this.note.noteAttachments ? this.note.noteAttachments.filter((x) => {
                const extension = x.filename.split('.').pop()

                return this.imageExtensions.includes(extension)

            }) : []
        },
        files() {
            return this.note.noteAttachments ? this.note.noteAttachments.filter((x) => {
                const extension = x.filename.split('.').pop()

                return !this.imageExtensions.includes(extension)

            }) : []
        },
        allFiles() {
            return this.note.noteAttachments ? this.note.noteAttachments : []
        },
        filesTitle() {

            let title = "<strong>Attachments:</strong><br>";

            if (this.files.length) {
                title += ` <small>${this.files.length} File${this.files.length > 1 ? 's' : ''}</small>`
            }

            if (this.files.length && this.images.length) {
                title += "<br>"

            }

            if (this.images.length) {
                title += ` <small>${this.images.length} Image${this.images.length > 1 ? 's' : ''}</small>`
            }

            return title;

        },
        selectedFinal: {
            set(val) {
                this.selfSelected = val;
            },
            get() {
                return this.selfSelected || this.selected;
            }
        }
    },
    watch: {
        note: {
            handler() {
                this.$nextTick(() => {

                    this.initializeOptionButtonTooltips();

                    if (this.files.length) {
                        // eslint-disable-next-line no-new
                        new this.$bootstrap.Tooltip(this.$refs.filesIndicator, {
                            template: "<div class=\"tooltip\" role=\"tooltip\"><div class=\"tooltip-inner\"></div></div>"
                        });
                    }

                    // if (this.images.length) {
                    //     // eslint-disable-next-line no-new
                    //     new this.$bootstrap.Tooltip(this.$refs.imageIndicator, {
                    //         template: "<div class=\"tooltip\" role=\"tooltip\"><div class=\"tooltip-inner\"></div></div>"
                    //     });
                    // }
                })
            },
            immediate: true
        },
        selected() {
            this.selfSelected = this.selected
        }
    },
    methods: {
        changeSelect(val) {
            this.$emit("selected", {value: val, id: this.note.id});

        },
        showOrEditNote() {
            if (!this.showOpen) {
                this.$emit('show', this.note)
            } else {
                this.$emit('edit', this.note)
            }
        }
    }
}
