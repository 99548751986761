


import {mapActions, mapGetters} from "vuex";
import SaveButton from "../save-button.vue";
import ContactBulkOperationForm from "../contacts/contact-bulk-operation-form.vue";
import modals from "../../mixins/modals";

export default {
    name: "ResearchReportReadershipBulkOperationModal",
    components: {ContactBulkOperationForm, SaveButton},
    mixins: [modals],
    props: {
        bulkData: {
            type: Object,
            required: true,
        }

    },
    data() {
        return {
            readershipOptions: [
                {
                    value: 'importContacts',
                    label: 'Import Contacts',
                },
            ],
            contactOptions: [
                {
                    value: 'filterContactList',
                    label: 'Filter Contact List',
                },
            ],
            operation: null,
            onReaderships: true,
            running: false,
            bulkContacts: null,
            loading: false,
        }
    },
    computed:
        {
            ...mapGetters({
                bulkContactFiltersHash: 'app/bulkContactFiltersHash',
            }),
        },
    watch: {
        bulkData: {
            handler() {
                this.loadBulkContacts();
            },
            immediate: true,
            deep: true,
        }
    },
    created() {
    },
    methods: {
        ...mapActions({
            setBulkContactFiltersHash: "app/setBulkContactFiltersHash",
        }),
        submitForm() {
            this.running = true;
            switch (this.operation) {
                case 'importContacts':
                    this.importContacts();
                    break;
                default:
                    console.error("Operation does not exist", this.operation);
            }
        },
        mounted() {
        },
        async loadBulkContacts() {
            this.loading = true;
            const responseFilters = await this.$axios.post("/api/contacts/save_bulk_filters", {
                filters: {
                    hash: this.bulkData.contacts.hash,
                }
            });
            this.setBulkContactFiltersHash(responseFilters.data.hash);
            this.bulkContacts = responseFilters.data;
            this.loading = false;
        },
        importContacts() {
            this.$axios.post('/api/research_reports/readership/import-contact', {hash: this.bulkData.readerships.hash})
                .then((respnse) => {
                    const toastId = this.generateUUID();
                    this.addToast({
                        type: "success",
                        title: "Success",
                        message: respnse.data.message,
                        id: toastId,
                    })
                    this.$nextTick(() => {
                        this.toggleToast(toastId);
                    });
                })
                .finally(() => {
                    this.running = false;
                    this.$emit('bulk-action-perfromed');
                })
        },
        contactBulkSubmitted() {
            this.running = false;
            this.$emit('bulk-action-perfromed');
        },
    }
}
