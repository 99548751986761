

import {mapGetters} from "vuex";
import LoadingSpinner from "../loading-spinner";
import PCheckIcon from "../p-check-icon";
import modals from "../../mixins/modals";
import SkeletonNotesGrid from "../skeleton/skeleton-notes-grid";
import NotesGrid from "./notes-grid";
import EditNoteModal from "./edit-note-modal";
import optionButtonTooltip from "~/mixins/option-button-tooltip";


export default {
    name: "NotesFeedbackCard",
    components: {SkeletonNotesGrid, PCheckIcon, EditNoteModal, NotesGrid, LoadingSpinner},
    mixins: [optionButtonTooltip, modals],
    props: {
        type: {
            type: String,
            default: "COMPANY",
            validator: function validator(value) {
                return ["COMPANY", "CONTACT", "EVENT", "PROJECT", "ALL"].includes(value);
            }
        },
        company: {
            type: Object,
            default: null,
        },
        contact: {
            type: Object,
            default: null,
        },
        event: {
            type: Object,
            default: null,
        },
        project: {
            type: Object,
            default: null,
        },
        userId: {
            type: Number,
            default: null,
        },
        listInteractionParticipants: {
            type: Boolean,
            default: true
        },
        forTargeting: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            noteTemplate: null,
            highlightDropArea: false,
            noteAttachments: [],
            loading: false,
            onlyMyNotes: false,
            showCompanyNotes: true,
            showContactNotes: true,
            showEventNotes: true,
            showProjectNotes: true,
            showTargetingNotes: this.forTargeting,
            noteEntries: [],
            noteToAdd: null,
            modalOpen: false,
            modal: null,
            allChecked: false,
            allSelected: 0,
            partialSelected: false,
            selectedNotes: [],
            search: "",
            searchTimeout: null,
            onlyDeletedNotes: false,
            initial: true,

        }
    },
    computed: {
        ...mapGetters({
            globalDrag: 'app/globalDrag',
        }),

        exportUrl() {
            let base = this.itemId
                ? `${this.$axios.defaults.baseURL}/api/companies/excel_export/notes/${this.type}/${this.itemId}`
                : `${this.$axios.defaults.baseURL}/api/companies/excel_export/notes/${this.type}/${this.userId}`;

            if (this.selectedNotes.length && this.selectedNotes.length !== this.noteEntriesFinal.length) {
                base += "?ids=" + this.selectedNotes.join(",");
            } else if (this.noteEntriesFinal.length !== this.noteEntries.length) {
                base += "?ids=" + this.noteEntriesFinal.map(x => x.id).join(",");
            }

            return base;

        },
        scrollbarOptions() {
            if (!this.modalOpen) {
                return {suppressScrollX: true, handlers: ['click-rail', 'drag-thumb', 'keyboard', 'wheel', 'touch']}
            }
            return {suppressScrollX: true, handlers: []}
        }, itemId() {
            if (this.company) {
                return this.company.id;
            } else if (this.contact) {
                return this.contact.id;
            } else if (this.event) {
                return this.event.id;
            } else if (this.project) {
                return this.project.id;
            }

            return null;
        },
        noteToAddType() {
            if (this.type !== "ALL") {
                return this.type;
            } else if (this.noteToAdd) {
                if (this.noteToAdd.company) {
                    return "COMPANY"
                } else if (this.noteToAdd.contact) {
                    return "CONTACT"
                } else if (this.noteToAdd.event) {
                    return "EVENT"
                } else if (this.noteToAdd.project) {
                    return "PROJECT"
                }
            }
            return "DEFAULT"
        },
        noteEntriesFinal() {
            let notes = this.noteEntries;

            if (!this.onlyDeletedNotes) {
                notes = notes.filter(note => {
                    return !note.deletedAt
                })
            } else {
                notes = notes.filter(note => {
                    return note.deletedAt
                })
            }

            if (this.onlyMyNotes && this.userInfo) {
                notes = notes.filter(note => {
                    return note.user.id === this.userInfo.id
                })
            }
            if (!this.showCompanyNotes) {
                notes = notes.filter(note => {
                    return !note.company
                })
            }
            if(!this.showTargetingNotes) {
                notes = notes.filter(note => {
                    return !note.noteType.isTargetingType
                })
            }
            if (!this.showContactNotes) {
                notes = notes.filter(note => {
                    return !note.contact
                })
            }
            if (!this.showEventNotes) {
                notes = notes.filter(note => {
                    return !note.event
                })
            }
            if (!this.showProjectNotes) {
                notes = notes.filter(note => {
                    return !note.project
                })
            }


            return notes;
        },
    },
    watch: {
        noteEntriesFinal() {
            this.selectedNotes = [];
            this.allSelected = 0;
            this.allChecked = false;
        },
        search() {
            clearTimeout(this.searchTimeout);
            this.searchTimeout = setTimeout(() => {
                this.loadNotes();
            }, 500);
        }
    },
    created() {

        if (!this.company && !this.contact && !this.event && !this.project && !this.userId) {
            // eslint-disable-next-line no-throw-literal
            throw "Either a user, company, contact, event or project has to be defined"
        }

        this.loadNotes();
    },

    methods: {
        selectAll() {

            this.$nextTick(() => {
                this.allSelected = this.partialSelected ? 0 : (this.allChecked ? 2 : 0);
                this.allChecked = !this.partialSelected;
                this.partialSelected = false;
            })

        },
        notesSelected(selectedNotes) {
            const someSelected = Object.values(selectedNotes).filter(x => x);
            this.partialSelected = !!(someSelected.length && someSelected.length < this.noteEntriesFinal.length);
            this.allChecked = !!(!this.partialSelected && someSelected.length && someSelected.length === this.noteEntriesFinal.length);
            this.allSelected = this.partialSelected ? 1 : (this.allChecked ? 2 : 0)
            this.selectedNotes = Object.keys(selectedNotes).filter(key => selectedNotes[key])
        },
        notesUpdated() {
            this.noteAttachments = [];
            this.loadNotes();
            if (this.modal) {
                this.closeModal(this.modal);
                this.modal = null
            }
        },
        loadNotes() {
            this.loading = true;

            const queryString = this.buildQueryString({
                search: this.search,
                targetingOnly: this.forTargeting,
            });

            let promise;
            if (this.itemId) {
                promise = this.$axios.get(`/api/notes/get/${this.type}/${this.itemId}?${queryString}`).then((response) => {
                    this.noteEntries = response.data;
                });
            } else {
                promise = this.$axios.get(`/api/notes/get/${this.type}/${this.userId}?${queryString}`).then((response) => {
                    this.noteEntries = response.data;
                });
            }

            promise.finally(() => {
                this.loading = false;
                this.initial = false;
            })
        },

        dragover(event) {
            event.preventDefault();
            this.highlightDropArea = true;
        },
        dragleave(event) {
            // Clean up
            this.highlightDropArea = false;
        },
        drop(event) {
            event.preventDefault();
            this.highlightDropArea = false;

            const promises = [];

            for (let i = 0; i < event.dataTransfer.files.length; i++) {

                const currentFile = event.dataTransfer.files[i];
                const promise = new Promise(resolve => {

                    const reader = new FileReader();
                    reader.readAsDataURL(currentFile);

                    // directory
                    reader.onerror = () => {
                        return resolve("error");
                    };

                    reader.onload = () => {
                        resolve(currentFile)
                    }
                });

                promises.push(promise)
            }

            Promise.all(promises).then((responses) => {
                const validFiles = responses.filter(x => x !== "error");

                if (validFiles.length) {
                    this.noteAttachments = validFiles;
                    this.addNote();
                }
            });

        },

        addNote() {
            const prom = new Promise((resolve) => {
                if (!this.noteTemplate) {
                    this.$axios.get("/api/notes/empty")
                        .then((response) => {
                            this.noteTemplate = response.data;
                            resolve()
                        });
                } else {
                    resolve();
                }
            });
            prom.then(() => {
                this.noteToAdd = Object.assign({}, this.noteTemplate);
                this.$nextTick(this.openAddModal);
            })
        },

        openAddModal() {
            this.modal = this.openModal(this.$refs.addNoteModal, {
                backdrop: "static",
                keyboard: false,
            });
        },

    }
}
