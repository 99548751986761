
export default {
    name: "PCheckIcon",
    props:{
        value:{
            type:[Boolean,String,Number],
            default:false,
        },
        partial:{
            type:Boolean,
            default:false,
        }
    },
    data(){
        return {
            content: this.value,

        }
    },
    watch:{
        value(val){
            this.content = val
        },

    },
    methods:{
        onChange(value){
            this.$emit("input",value);
            this.$emit("change",value);
        }
    }
}
