
import fileDownload from "../../mixins/file-download";
import SaveButton from "../save-button";

export default {
    name: "DownloadTransactionsModal",
    components: {SaveButton},
    mixins: [fileDownload],
    props: {
        id: {
            type: String,
            default: "transactionDownloadModal"
        },
        query: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            mode: 'deals',
            downloadOptions: [
                {value: 'deals', label: 'Deals'},
                {value: 'checklist', label: 'Deal Checklist'}
            ],
            isLoading: false,
        }
    },
    // watch: {
    //     mode() {
    //         console.log("Mode", this.mode);
    //         if (this.mode === "deals") {
    //             console.log(this.transactionsExportUrl);
    //         } else if (this.mode === "checklist") {
    //             console.log(this.transactionDatasExportUrl);
    //         }
    //     }
    // },
    methods: {
        download() {
            this.isLoading = true;

            let exportPath;

            switch (this.mode) {
                case 'deals':
                    exportPath = '/api/transactions/excel_export';
                    break;

                case 'checklist':
                    exportPath = '/api/transaction_datas/excel_export';
                    break;

                default:
                    // eslint-disable-next-line no-throw-literal
                    throw `Unknown mode "${this.mode}"`;
            }

            this.saveQueryAndExport(this.query, exportPath).then(() => {
                this.$emit('download-fired');
            }).finally(() => {
                this.isLoading = false;
            });
        },
    }
}
