
import moment from "moment";
import {mapActions, mapGetters} from "vuex";
import domtoimage from 'dom-to-image';
import StockChartCard from "../company/stock-chart-card";
import UploadDropZone from "../upload-drop-zone";
import PCheckIcon from "../p-check-icon";
import ConfirmModal from "../confirm-modal";
import InfoText from "../info-text";
import NoteReminderForm from "./note-reminder-form";
import modals from "~/mixins/modals";
import MimeTypeDetector from "~/mixins/mime-type-detector";


export default {
    name: "NoteForm",
    components: {InfoText, ConfirmModal, PCheckIcon, UploadDropZone, StockChartCard, NoteReminderForm},
    mixins: [
        MimeTypeDetector,
        modals
    ],
    props: {
        company: {
            type: Object,
            default: null,
        },
        contact: {
            type: Object,
            default: null,
        },
        event: {
            type: Object,
            default: null,
        },
        project: {
            type: Object,
            default: null,
        },
        interaction: {
            type: Object,
            default: null,
        },
        note: {
            type: [Object, null],
            default: null,
        },
        attachments: {
            type: [Array, FileList],
            default: () => {
                return []
            }
        },
        type: {
            type: String,
            default: "DEFAULT",
            validator: function validator(value) {
                // TODO Does adding interaction to below make sense?
                return ["COMPANY", "CONTACT", "EVENT", "INTERACTION", "PROJECT", "DEFAULT"].includes(value);
            }
        },
        formId: {
            type: [String],
            default: "note-form"
        },
        showStockOption: {
            type: Boolean,
            default: true,
        },
        doStayAfterSubmit: {
            type: Boolean,
            default: false,
        },
        forTargeting: {
            type: Boolean,
            default: false,
        },
        titleSuggestion: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            noteWorkingCopy: {},
            noteTypes: [],
            attachmentsWorkingCopy: [],
            attachmentData: [],
            highlightDropArea: false,
            attachmentsToDelete: [],
            noteAttachmentsWorkingCopy: [],
            convertAttachment: {},
            supportedMimeTypes: [
                "text/plain",
                "text/html",
                "application/docx",
                "application/doc"
            ],
            supportedExtensions: [
                "doc",
                "docx",
                "odt",
                "txt",
                "htm",
                "html",
            ],
            mimeTypes: {},
            noteUpdated: this.generateUUID(),
            companyWorkingCopy: null,
            contactWorkingCopy: null,
            eventWorkingCopy: null,
            interactionWorkingCopy: null,
            projectWorkingCopy: null,
            autosaveTimeout: null,
            autosaveDelay: 5000,
            autoSaving: false,
            initialBody: false,
            initialCall: false,
            addStockChart: false,
            imageExtensions: ['png', 'gif', 'jpg', 'jpeg'],
            attachmentIndexToConvert: null,
            attachmentConvertingIndex: null,
            reminderWorkingCopy: [],
            remindersToAdd: [],
            remindersToDelete: [],
            noteReminderFormKey: "noteReminderFormKey",
            questionsCollapseShown: false,
            followUpCollapseShown: false,
            otherCollapseShown: false,
        }
    },
    computed: {
        ...mapGetters({
            globalDrag: 'app/globalDrag',
        }),
        isNew() {
            return !this.noteWorkingCopy.id;
        },
        bodyCompleted() {
            return !!(this.noteWorkingCopy.body || Object.values(this.convertAttachment).filter(x => x).length);
        },
        typeSelected() {
            return !!(this.type !== "DEFAULT" || this.typeComplete);
        },
        typeComplete() {
            return !!(this.companyWorkingCopy || this.contactWorkingCopy || this.eventWorkingCopy || this.interactionWorkingCopy || this.projectWorkingCopy);
        },
        completed() {
            return !!(this.bodyCompleted && this.noteWorkingCopy.title && this.noteWorkingCopy.noteType && this.typeComplete);
        },
        combinedImages() {
            return [
                ...this.noteAttachmentsWorkingCopy.map((x, i) => ({...x, persistedIndex: i})),
                ...this.attachmentsWorkingCopy.map((x, i) => ({...x, freshIndex: i})),
            ].filter(x => this.isImage(x))
        },
        media() {
            return this.combinedImages.map((img) => {
                return { // For an image
                    type: 'image', // Can be omitted for image
                    thumb: img.fileUrl,
                    src: img.fileUrl,
                    caption: '', // Optional
                    srcset: null // Optional for displaying responsive images
                }
            });
        },
        date: {
            get() {
                if (this.noteWorkingCopy && this.noteWorkingCopy.date) {
                    /* const date = new Date(this.contactWorkingCopy.birthday);
                    const day = ("0" + date.getDate()).slice(-2);
                    const month = ("0" + (date.getMonth() + 1)).slice(-2);
                    const year = date.getFullYear();
                    return this.padWithZeros(year, 4) + "-" + (month) + "-" + (day); */
                    return moment(this.noteWorkingCopy.date).format("YYYY-MM-DD");
                } else {
                    this.$set(this.noteWorkingCopy, "date", moment().format("YYYY-MM-DD"))
                    return moment().format("YYYY-MM-DD");
                }
            },
            set(newValue) {
                this.$set(this.noteWorkingCopy, "date", newValue || null)
            }
        },
        isInteractionNote() {
            return this.type === 'INTERACTION' || this.type === 'COMPANY' && this.interactionWorkingCopy;
        },
        isCompanyNote() {
            return this.type === 'COMPANY' && !this.interactionWorkingCopy;
        },
        supportInstantUpload() {
            return this.attachmentData.length === 0 && !this.noteWorkingCopy.body
        }
    },
    watch: {
        // For the below properties, note and interaction and others, we want new workingCopies to
        // be created and other things to happen when these change.
        note: {
            handler(val) {
                this.watcherHandler(val);
            },
            immediate: true
        },
        company: {
            handler(val) {
                this.watcherHandler(val);
            },
            immediate: true
        },
        contact: {
            handler(val) {
                this.watcherHandler(val);
            },
            immediate: true
        },
        event: {
            handler(val) {
                this.watcherHandler(val);
            },
            immediate: true
        },
        interaction: {
            handler(val) {
                this.watcherHandler(val);
            },
            immediate: true
        },


        attachments: {
            handler() {
                this.$nextTick(() => {

                    this.attachmentsWorkingCopy = Array.apply([], this.attachments.map(file =>
                        ({
                            filename: file.name,
                            type: file.type,
                            fileUrl: URL.createObjectURL(file),
                            extension: file.name.split('.').pop(),
                            file,
                        })
                    ));

                    if (this.noteWorkingCopy && this.noteWorkingCopy.title === this.titleSuggestion && !this.noteWorkingCopy.id && this.attachmentsWorkingCopy.length) {
                        this.noteWorkingCopy.title = this.attachmentsWorkingCopy[0].file.name.replace(/\.[^/.]+$/, "")
                    }
                })

            },
            immediate: true

        },
        attachmentsWorkingCopy: {
            handler() {
                const supportInstantUpload = this.supportInstantUpload;

                this.attachmentData = [];
                this.mimeTypes = [];
                this.attachmentsWorkingCopy.forEach((attachment, index) => {
                    this.addAttachment(attachment.file)
                    this.loadMimeType(attachment.file).then((mime) => {
                        if (supportInstantUpload && this.attachmentData.length === 1 && index === 0 && this.supportedMimeTypes.includes(mime) && this.supportedExtensions.includes(attachment.extension)) {
                            this.$set(this.convertAttachment, attachment.file.name, true)
                            this.$nextTick(() => {
                                clearTimeout(this.autosaveTimeout)
                                this.confirmConvertAttachment(index)
                            })
                        } else {
                            this.$set(this.convertAttachment, attachment.file.name, false)
                        }
                        this.$set(this.mimeTypes, attachment.file.name, mime)

                    })
                })

            },
            immediate: true
        },
        companyWorkingCopy: {
            handler(val) {
                if (val) {
                    this.noteWorkingCopy.company = val["@id"];
                } else {
                    this.noteWorkingCopy.company = null
                }
            },
            immediate: true
        },
        contactWorkingCopy: {
            handler(val) {
                if (val) {
                    this.noteWorkingCopy.contact = val["@id"];
                } else {
                    this.noteWorkingCopy.contact = null
                }
            },
            immediate: true
        },
        eventWorkingCopy: {
            handler(val) {
                if (val) {
                    this.noteWorkingCopy.event = val["@id"];
                } else {
                    this.noteWorkingCopy.event = null
                }
            },
            immediate: true
        },
        interactionWorkingCopy: {
            handler(val) {
                if (val) {
                    this.noteWorkingCopy.interaction = val["@id"];
                } else {
                    this.noteWorkingCopy.interaction = null
                }
            },
            immediate: true
        },
        projectWorkingCopy: {
            handler(val) {
                if (val) {
                    this.noteWorkingCopy.project = val["@id"];
                } else {
                    this.noteWorkingCopy.project = null
                }
            },
            immediate: true
        },
        "noteWorkingCopy.body": {
            handler(body, oldBody) {

                if (this.getNormalizedHtml(body) === this.getNormalizedHtml(oldBody)) {
                    console.log("Body stayed the same");
                    return;
                }

                console.log("Clearing timeout for autosave (a) => there was a change in body!");
                clearTimeout(this.autosaveTimeout);

                if (body && !this.initialBody) {
                    this.$nextTick(() => {
                        this.setWarnNotSaved(true);

                        if (this.completed && !this.$refs.noteForm.hasErrors) {
                            console.log("Setting timeout for autosave (noteWorkingCopy.body)");
                            this.autosaveTimeout = setTimeout(() => {
                                this.autoSaving = true;
                                this.$formulate.submit(this.formId);
                                this.initialBody = false;
                            }, this.autosaveDelay);
                        } else {
                            this.initialCall = false;
                            console.log("Setting timeout for autosave (toast)");
                            this.autosaveTimeout = setTimeout(() => {
                                const toastId = this.generateUUID();
                                this.addToast({
                                    type: "info",
                                    htmlMessage:
                                        "<p>Please fill in required fields to enable auto-save:</p>" +
                                        "<ul><li>Title</li><li>Type</li><li>Either body, or convertable attachment</li>" +
                                        "<li>Company, contact, event, interaction, or project</li></ul>",
                                    title: 'Information missing',
                                    id: toastId,
                                });
                                this.$nextTick(() => {
                                    this.toggleToast(toastId);
                                });
                            }, this.autosaveDelay)
                        }
                    })
                } else {
                    console.log("Not setting autosave timeout because initialBody == true or body empty, setting initialBody = false.");
                    this.initialBody = false;
                }
            }
        },
        "noteWorkingCopy.private": {
            handler(bool) {
                if (bool) {
                    this.$set(this.noteWorkingCopy, "othersCanEdit", false);
                }
            }
        },
        "noteWorkingCopy.noteType": {
            handler(val) {
                if (val) {
                    const currentType = this.noteTypes.find(x => x["@id"] === val);
                    if (currentType && currentType.isTargetingType) {
                        this.$set(this.noteWorkingCopy, "private", false);
                        this.$set(this.noteWorkingCopy, "othersCanEdit", true);
                        if (!this.forTargeting) {
                            const toastId = this.generateUUID();
                            this.addToast({
                                type: "info",
                                title: 'Note has been made public and editable by others',
                                id: toastId,
                            });
                            this.$nextTick(() => {
                                this.toggleToast(toastId);
                            });
                        }
                    }
                }
            }
        },
        completed(bool) {
            if (bool && !this.initialCall) {
                console.log("Clearing timeout for autosave (b)");
                clearTimeout(this.autosaveTimeout);
                this.$nextTick(() => {
                    console.log("Setting timeout for autosave (completed)");
                    this.autosaveTimeout = setTimeout(() => {
                        this.initialBody = false;
                        this.autoSaving = true;
                        this.$formulate.submit(this.formId);
                    }, this.autosaveDelay)
                })
            } else {
                this.initialCall = false;
            }
        },
    },
    beforeDestroy() {
        this.setWarnNotSaved(false);
        console.log("Clearing timeout for autosave (c)");
        clearTimeout(this.autosaveTimeout);
    },
    mounted() {
        this.loadNoteTypes();
        if(!this.noteWorkingCopy?.title && this.titleSuggestion)
            this.$set(this.noteWorkingCopy, "title", this.titleSuggestion);
        this.$refs.confirmConvertAttachment.$el.addEventListener('show.bs.modal', () => {
            this.$emit('toggle-confirm', true);
        })
        this.$refs.confirmConvertAttachment.$el.addEventListener('hide.bs.modal', () => {
            this.$emit('toggle-confirm', false);
        })
    },
    methods: {
        ...mapActions({
            setWarnNotSaved: 'app/setWarnNotSaved'
        }),
        getNormalizedHtml(html) {
            return (html || '')
                .replaceAll('&nbsp;', ' ')
                .replaceAll('\u00A0', ' ');
        },

        watcherHandler(val) {
            // console.warn("Handler is called with value", JSON.stringify(val));
            if (val) {
                this.initialCall = true;
                this.initialBody = true;

                this.companyWorkingCopy = null;
                this.contactWorkingCopy = null;
                this.eventWorkingCopy = null;
                this.interactionWorkingCopy = null;
                this.projectWorkingCopy = null;

                this.noteWorkingCopy = Object.assign({}, this.note);
                if (!this.noteWorkingCopy.title) {
                    this.noteWorkingCopy.title = this.titleSuggestion;
                }
                // prevent null error
                if (!this.noteWorkingCopy.body) {
                    this.noteWorkingCopy.body = "";
                }

                if (this.note.noteType) {
                    this.noteWorkingCopy.noteType = `/api/note_types/${this.note.noteType.id}`
                } else if (this.noteTypes.length) {
                    this.noteWorkingCopy.noteType = this.noteTypes[0]["@id"]
                }

                switch (this.type) {
                    case "COMPANY":
                        if (this.note.company) {
                            this.companyWorkingCopy = Object.assign({}, this.note.company);
                        } else if (this.company) {
                            this.companyWorkingCopy = Object.assign({}, this.company);
                        } else if (this.note.interaction) {
                            this.interactionWorkingCopy = Object.assign({}, this.note.interaction);
                        } else if (this.interaction) {
                            this.interactionWorkingCopy = Object.assign({}, this.interaction);
                        }
                        break;

                    case "CONTACT":
                        if (this.note.contact) {
                            this.contactWorkingCopy = Object.assign({}, this.note.contact);
                        } else if (this.contact) {
                            this.contactWorkingCopy = Object.assign({}, this.contact);
                        }
                        break;

                    case "EVENT":
                        if (this.note.event) {
                            this.eventWorkingCopy = Object.assign({}, this.note.event);
                        } else if (this.event) {
                            this.eventWorkingCopy = Object.assign({}, this.event);
                        }
                        break;

                    case "PROJECT":
                        if (this.note.project) {
                            this.projectWorkingCopy = Object.assign({}, this.note.project);
                        } else if (this.project) {
                            this.projectWorkingCopy = Object.assign({}, this.project);
                        }
                        break;

                    case "INTERACTION":
                        if (this.note.interaction) {
                            this.interactionWorkingCopy = Object.assign({}, this.note.interaction);
                        } else if (this.interaction) {
                            this.interactionWorkingCopy = Object.assign({}, this.interaction);
                        }
                        break;
                }
                this.remindersToAdd = [];
                this.remindersToDelete = [];
                this.noteAttachmentsWorkingCopy = Array.apply([], this.noteWorkingCopy.noteAttachments);
                this.noteUpdated = this.generateUUID();


                this.$axios.get(`/api/note_reminders?note=${this.noteWorkingCopy.id}`)
                    .then((response) => {
                        this.reminderWorkingCopy = response.data['hydra:member'];
                    })
                    .catch((error) => {
                        this.reminderWorkingCopy = [];
                        console.error(error);
                    });
            } else {
                this.reminderWorkingCopy = [];
            }
        },

        spliceAttachment(index) {
            this.attachmentsWorkingCopy.splice(index, 1);
            this.attachmentData.splice(index, 1);
        },
        deleteAttachment(index) {
            this.attachmentsToDelete.push(this.noteAttachmentsWorkingCopy[index]);
            this.noteAttachmentsWorkingCopy.splice(index, 1);
            // this.noteWorkingCopy.noteAttachments.splice(index,1);
        },
        addAttachment(file) {
            this.fileURL = URL.createObjectURL(file);
            const reader = new FileReader();
            reader.readAsDataURL(file)
            reader.onload = () => {
                this.attachmentData.push(Object.assign({'filenameOriginal': file.name}, {
                    files:
                        {
                            fileData: {
                                data: reader.result,
                                extension: file.name.split('.').pop(),
                                checksumField: "fileChecksum",
                            }
                        }
                }))
            };
        },

        loadNoteTypes() {
            this.$axios.get("/api/note_types")
                .then((response) => {
                    this.noteTypes = response.data['hydra:member'];

                    if (!this.noteWorkingCopy.noteType) {
                        if (this.forTargeting) {
                            this.noteWorkingCopy.noteType = this.noteTypes.find(x => x.isTargetingType)["@id"]
                        } else {
                            this.noteWorkingCopy.noteType = this.noteTypes[0]["@id"]
                        }
                    }
                });
        },
        dragover(event) {
            event.preventDefault();
            this.highlightDropArea = true;
        },
        dragleave(event) {
            // Clean up
            this.highlightDropArea = false;
        },
        drop(event) {
            event.preventDefault();
            this.highlightDropArea = false;
            event.dataTransfer.files.forEach((file) => {
                this.attachmentsWorkingCopy.push({
                    filename: file.name,
                    type: file.type,
                    extension: file.name.split('.').pop(),
                    fileUrl: URL.createObjectURL(file),
                    file,
                });
            })
        },
        setAttachmentData(files) {
            files.forEach((file) => {
                this.attachmentsWorkingCopy.push({
                    filename: file.name,
                    type: file.type,
                    extension: file.name.split('.').pop(),
                    fileUrl: URL.createObjectURL(file),
                    file,
                });
            })
        },
        async submitForm() {
            this.$emit("submitting")

            console.log("Clearing timeout for autosave (d)");
            clearTimeout(this.autosaveTimeout)

            let noteToSave = structuredClone(this.noteWorkingCopy);

            noteToSave.autoSaved = !!this.autoSaving;

            let noteId;
            if (this.isNew) {

                this.noteWorkingCopy.noteAttachments = this.note.noteAttachments ? this.note.noteAttachments.map(x => `/api/note_attachments/${x.id}`) : [];
                if (noteToSave.user && this.noteWorkingCopy.user.id) {
                    noteToSave.user = "/api/users/" + noteToSave.user.id;
                }
                const noteResponse = await this.$axios.post("/api/notes", noteToSave)
                noteId = noteResponse.data["@id"];
                noteToSave = noteResponse.data;
                // prevent null error
                if (!noteToSave.body) {
                    noteToSave.body = "";
                }

                noteToSave.noteType = `/api/note_types/${noteToSave.noteType.id}`


                if (noteToSave.company) {
                    this.companyWorkingCopy = Object.assign({}, noteToSave.company);
                }
                if (noteToSave.contact) {
                    this.contactWorkingCopy = Object.assign({}, noteToSave.contact);
                }
                if (noteToSave.event) {
                    this.eventWorkingCopy = Object.assign({}, noteToSave.event);
                }
                if (noteToSave.project) {
                    this.projectWorkingCopy = Object.assign({}, noteToSave.project);
                }
                if (noteToSave.interaction) {
                    this.interactionWorkingCopy = Object.assign({}, noteToSave.interaction);
                }


                this.noteAttachmentsWorkingCopy = Array.apply([], noteToSave.noteAttachments);
                this.noteUpdated = this.generateUUID();


            } else {

                noteToSave.noteAttachments =
                    noteToSave.noteAttachments ?
                        noteToSave.noteAttachments.map(x => `/api/note_attachments/${x.id}`) :
                        [];

                noteToSave.noteReminders =
                    noteToSave.noteReminders ?
                        noteToSave.noteReminders.map(x => `/api/note_reminders/${x.id}`) :
                        [];

                noteId = "/api/notes/" + noteToSave.id;
                if (typeof noteToSave.user === 'object' && noteToSave.user !== null) {
                    noteToSave.user = `/api/users/${noteToSave.user.id}`
                }

                const noteResponse = await this.$axios.patch("/api/notes/" + noteToSave.id, noteToSave, {
                    headers: {
                        'Content-Type': 'application/merge-patch+json'
                    }
                })
                noteToSave = noteResponse.data;

            }

            if (this.addStockChart) {
                const imageData = await new Promise((resolve) => {
                    domtoimage.toPng(document.getElementById('chartWithInfos'))
                        .then(function (dataUrl) {
                            resolve(dataUrl)
                        })
                        .catch(function (error) {
                            console.error('oops, something went wrong!', error);
                        });
                })

                this.attachmentData.push(Object.assign({'filenameOriginal': `stock-chart-${moment().format("YYYY-MM-DD")}.png`}, {
                    files:
                        {
                            fileData: {
                                data: imageData,
                                extension: "png",
                                checksumField: "fileChecksum",
                            }
                        }
                }))
            }


            for (const attachment of this.attachmentData) {
                attachment.note = noteId;
                const noteAttachmentResponse = await this.$axios.post("/api/note_attachments", attachment)

                if (this.convertAttachment[attachment.filenameOriginal]) {
                    const convertResponse = await this.$axios.post("/api/note_attachment/convert/" + noteAttachmentResponse.data.id)

                    if (!convertResponse.data.status && convertResponse.data.message) {

                        const toastId = this.generateUUID();
                        this.addToast({
                            type: "danger",
                            message: convertResponse.data.message,
                            title: `Conversion Failed: ${attachment.filenameOriginal}`,
                            id: toastId,
                        })
                        this.$nextTick(() => {
                            this.toggleToast(toastId);
                        })
                    }
                }
            }

            for (const attachment of this.attachmentsToDelete) {
                await this.$axios.delete(`/api/note_attachments/${attachment.id}`);
            }


            for (const reminder of this.remindersToAdd) {
                reminder.note = noteId
                const noteReminderResponse = await this.$axios.post("/api/note_reminders", reminder);
                console.log(noteReminderResponse);
            }

            for (const reminder of this.remindersToDelete) {
                await this.$axios.delete(`/api/note_reminders/${reminder.id}`);
            }


            // Reload Note from server and put it back into form, necessary for attachments who now have IDs, e.g., and future requests should be PATCH not POST
            const noteResponse = await this.$axios.get(noteId);
            if (this.autoSaving) {
                // Preserve body so that ckeditor does not reset the cursor
                noteResponse.data.body = this.noteWorkingCopy.body;
                this.noteWorkingCopy = noteResponse.data;
            }
            this.noteWorkingCopy = noteResponse.data;

            this.attachmentData = [];
            this.attachmentsToDelete = [];
            this.attachmentsWorkingCopy = [];
            this.noteAttachmentsWorkingCopy = Array.apply([], this.noteWorkingCopy.noteAttachments);

            this.remindersToAdd = [];
            this.remindersToDelete = [];
            this.reminderWorkingCopy = Array.apply([], this.noteWorkingCopy.noteReminders)

            this.$emit("submitted", {
                autoSaved: this.autoSaving,
                stayAfterSubmit: this.doStayAfterSubmit,
                id: noteId,
            });

            // Prepare and show toast
            const toastId = this.generateUUID();
            if (this.autoSaving) {
                this.addToast({
                    type: "success",
                    title: "Auto-saved",
                    id: toastId,
                });
            } else {
                this.addToast({
                    type: "success",
                    title: "Saved",
                    message: "Note saved successfully",
                    id: toastId,
                });
            }
            this.$nextTick(() => {
                this.toggleToast(toastId);
            })

            // Reset data
            if (!this.autoSaving && !this.doStayAfterSubmit) {
                this.noteWorkingCopy = {};
            }
            this.autoSaving = false;
            this.addStockChart = false;
            this.setWarnNotSaved(false);

        },
        openLightbox(index, freshAttachment = false) {
            let realIndex;
            if (!freshAttachment) {
                realIndex = this.combinedImages.findIndex(x => x.persistedIndex === index);
            } else {
                realIndex = this.combinedImages.findIndex(x => x.freshIndex === index);
            }
            this.$refs.lightbox.showImage(realIndex);
        },
        isImage(attachment) {
            return this.imageExtensions.includes(attachment.filename.split('.').pop());
        },
        confirmConvertAttachment(index) {
            this.attachmentIndexToConvert = index;
            this.openModal(this.$refs.confirmConvertAttachment);
        },
        cancelConvertAttachment() {
            this.attachmentIndexToConvert = null;
        },
        async doConvertAttachment(index) {
            this.attachmentConvertingIndex = index;

            const attachment = this.attachmentData[0];

            const convertResponse = await this.$axios.post("/api/note_attachment/create_html_from_file", attachment)

            if (!convertResponse.data.status && convertResponse.data.message) {
                const toastId = this.generateUUID();
                this.addToast({
                    type: "danger",
                    message: convertResponse.data.message,
                    title: `Conversion Failed: ${attachment.filenameOriginal}`,
                    id: toastId,
                })
                this.$nextTick(() => {
                    this.toggleToast(toastId);
                })
            } else {
                this.spliceAttachment(0);
                this.$set(this.noteWorkingCopy, 'body', convertResponse.data.body);
            }

            this.attachmentConvertingIndex = null;
        },
        reminderAdded(dateTime, timezone) {
            const date = new Date(dateTime);
            const newReminder = {
                user: `/api/users/${this.userInfo.id}`,
                date,
                timezone,
            }
            this.remindersToAdd.push(newReminder);
            this.noteReminderFormKey = this.generateUUID();
        },
        spliceReminder(index) {
            this.remindersToAdd.splice(index, 1);
        },
        deleteReminder(index) {
            this.remindersToDelete.push(this.reminderWorkingCopy[index]);
            this.reminderWorkingCopy.splice(index, 1);
            console.log(this.reminderWorkingCopy.length);
            console.log(this.reminderWorkingCopy[index]);
            // this.noteWorkingCopy.noteAttachments.splice(index,1);
        },
    }
}
