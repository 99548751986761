
import moment from "moment";
import {SEVERITY_TIERS} from "../mixins/red-flag";
import LoadingSpinner from "./loading-spinner";
import SaveButton from "./save-button";
import ContactName from "./contacts/contact-name";

export default {
    name: "RedFlagModal",
    components: {ContactName, SaveButton, LoadingSpinner},
    props: {
        contactId: {
            type: Number,
            required: false,
            default: null,
        },
        companyId: {
            type: Number,
            required: false,
            default: null,
        },
    },
    data() {
        return {
            SEVERITY_TIERS,
            uuid: this.generateUUID(),
            tableUpdated: moment().valueOf(),
            isLoading: true,
            isSaving: false,
            isDeleting: false,
            contact: null,
            company: null,
            redFlag: null,
            config: {
                columns: [
                    {
                        headline: "User",
                        sort: true,
                    },
                    {
                        headline: "Reason",
                        sort: true,
                    },
                    {
                        headline: "Severity",
                        sort: true,
                    },
                    {
                        headline: "Last Change",
                        sort: true,
                    }
                ],
                pagination: 10,
                ajaxUrl: '',
            },
            showTable: false,
            isTableLoading: true,
        };
    },
    computed: {
        isForContact() {
            return this.contactId !== null;
        },
        isForCompany() {
            return this.companyId !== null;
        },
        title() {
            if (this.isForContact) {
                return this.contact.nameWithCompany;
            } else if (this.isForCompany) {
                return this.company.name;
            } else {
                // eslint-disable-next-line no-throw-literal
                throw "Neither for contact nor for company!";
            }
        },
        filters() {
            const result = {
                excludeCurrentUser: true,
            };

            if (this.isForContact) {
                result.contact = this.contactId;
            } else if (this.isForCompany) {
                result.company = this.companyId;
            }

            return result;
        },
    },
    mounted() {
        this.$refs.redFlagModal.addEventListener('shown.bs.modal', () => {
            this.isLoading = true;

            const promises = [
                this.loadContactOrCompany(),
                this.loadOfCurrentUser(),
            ];

            Promise.all(promises).then(() => {
                this.isLoading = false;

                this.$nextTick(() => {
                    if (this.isForContact) {
                        this.config.ajaxUrl = '/api/user_flags_contacts/list';
                    } else if (this.isForCompany) {
                        this.config.ajaxUrl = '/api/user_flags_companies/list';
                    } else {
                        // eslint-disable-next-line no-throw-literal
                        throw "Neither for contact nor for company!";
                    }

                    this.tableUpdated = moment().valueOf();
                });
            })
        });

        this.$refs.redFlagModal.addEventListener('hidden.bs.modal', () => {

        });
    },
    methods: {
        onRowsUpdated(data) {
            this.isTableLoading = false;
            this.showTable = data.all && data.all > 0;
        },
        loadOfCurrentUser() {
            let url;
            if (this.isForContact) {
                url = `/api/user_flags_contacts/get_or_empty/${this.contactId}`;
            } else if (this.isForCompany) {
                url = `/api/user_flags_companies/get_or_empty/${this.companyId}`;
            } else {
                // eslint-disable-next-line no-throw-literal
                throw "Neither for contact nor for company!";
            }

            return this.$axios.get(url).then(response => {
                this.redFlag = response.data;
            });
        },
        loadContactOrCompany() {
            if (this.isForContact) {
                return this.$axios.get(`/api/contacts/${this.contactId}`).then(response => {
                    this.contact = response.data;
                });
            } else if (this.isForCompany) {
                return this.$axios.get(`/api/companies/${this.companyId}`).then(response => {
                    this.company = response.data;
                });
            } else {
                // eslint-disable-next-line no-throw-literal
                throw "Neither for contact nor for company!";
            }
        },
        saveFlag() {
            this.isSaving = true;

            if (this.redFlag.id) {
                let url;
                if (this.isForContact) {
                    url = `/api/user_flags_contacts/${this.redFlag.id}`;
                } else if (this.isForCompany) {
                    url = `/api/user_flags_companies/${this.redFlag.id}`;
                } else {
                    // eslint-disable-next-line no-throw-literal
                    throw "Neither for contact nor for company!";
                }

                this.$axios.patch(url, this.redFlag, {
                    headers: {
                        'Content-Type': 'application/merge-patch+json'
                    }
                }).then(() => {
                    const toastId = this.generateUUID();
                    this.addToast({
                        type: "success",
                        title: "Reason Saved",
                        message: "Your reason was successfully saved",
                        id: toastId,
                    });
                    this.$nextTick(() => {
                        this.toggleToast(toastId);
                    });
                }).finally(() => {
                    this.isSaving = false;
                    this.$emit("updated");
                });

            } else {
                let url;
                if (this.isForContact) {
                    url = "/api/user_flags_contacts";
                } else if (this.isForCompany) {
                    url = "/api/user_flags_companies";
                } else {
                    // eslint-disable-next-line no-throw-literal
                    throw "Neither for contact nor for company!";
                }

                this.$axios.post(url, this.redFlag).then(() => {
                    const toastId = this.generateUUID();
                    this.addToast({
                        type: "success",
                        title: "Reason Saved",
                        message: "Your reason was successfully saved",
                        id: toastId,
                    });
                    this.$nextTick(() => {
                        this.toggleToast(toastId);
                    });
                }).finally(() => {
                    this.isSaving = false;
                    this.$emit("updated");
                });
            }
        },
        deleteFlag() {
            this.isDeleting = true;

            let url;
            if (this.isForContact) {
                url = `/api/user_flags_contacts/${this.redFlag.id}`;
            } else if (this.isForCompany) {
                url = `/api/user_flags_companies/${this.redFlag.id}`;
            } else {
                // eslint-disable-next-line no-throw-literal
                throw "Neither for contact nor for company!";
            }

            this.$axios.delete(url).then(() => {
                const toastId = this.generateUUID();
                this.addToast({
                    type: "success",
                    title: "Reason Deleted",
                    message: "Your reason was successfully deleted",
                    id: toastId,
                });
                this.$nextTick(() => {
                    this.toggleToast(toastId);
                });
            }).finally(() => {
                this.isDeleting = false;
                this.$emit("updated");
            })
        },
        close() {
            this.$emit("closed");
        },
    },
}
