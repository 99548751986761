
import {mapGetters} from "vuex";
import SaveButton from "../save-button";
import ParticipantsAndCompanies from "../interactions/participants-and-companies";
import NoteForm from "./note-form";
import EditingHistory from "~/components/editing-history";
import optionButtonTooltip from "~/mixins/option-button-tooltip";

export default {
    name: "EditNoteModal",
    components: {ParticipantsAndCompanies, SaveButton, NoteForm, EditingHistory},
    mixins: [optionButtonTooltip],
    props: {
        id: {
            type: String,
            default: "newNoteModal"
        },
        company: {
            type: Object,
            default: null,
        },
        contact: {
            type: Object,
            default: null,
        },
        event: {
            type: Object,
            default: null,
        },
        interaction: {
            type: Object,
            default: null,
        },
        project: {
            type: Object,
            default: null,
        },

        note: {
            type: [Object, null],
            default: null,
        },

        attachments: {
            type: [Array, FileList],
            default: () => {
                return []
            }
        },
        type: {
            type: String,
            default: "DEFAULT",
            validator: function validator(value) {
                // TODO Does adding interaction to below make sense?
                return ["COMPANY", "CONTACT", "EVENT", "INTERACTION", "PROJECT", "DEFAULT"].includes(value);
            }
        },
        forTargeting: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            loading: false,
            updated: this.generateUUID(),
            justAutoSaved: false,
            savedNoteId: null,
            showAutoSavedHistories: false,
            doStayAfterSubmit: false,
            savedOnPurpose: false,
            confirmOpen: false,
        }
    },
    computed: {
        ...mapGetters({
            warnNotSaved: "app/warnNotSaved",
        }),
        titleSuggestion() {
            if (this.contactOfNode && this.contactOfNode.nameWithCompany) {
                return "Note for Contact: " + this.contactOfNode.nameWithCompany;
            } else if (this.companyOfNode && this.companyOfNode.name) {
                return "Note for Company: " + this.companyOfNode.name;
            } else if (this.eventOfNode && this.eventOfNode.subject) {
                return "Note on Event: " + this.eventOfNode.subject;
            } else if (this.interactionOfNode && this.interactionOfNode.subject) {
                return "Note on Interaction: " + this.interactionOfNode.subject;
            } else {
                return 'New Note';
            }
        },
        isNew() {
            return this.note.id == null;
        },
        historyEntries() {
            if (!this.showAutoSavedHistories) {
                return this.note.editHistories.filter(x => !x.autoSaved);
            }
            return this.note.editHistories;
        },
        interactionOfNode() {
            if (this.node && this.node.interaction) {
                return this.node.interaction;
            } else {
                return this.interaction;
            }
        },
        companyOfNode() {
            if (this.node && this.node.company) {
                return this.node.company;
            } else {
                return this.company;
            }
        },
        contactOfNode() {
            if (this.node && this.node.contact) {
                return this.node.contact;
            } else {
                return this.contact;
            }
        },
        eventOfNode() {
            if (this.node && this.node.event) {
                return this.node.event;
            } else {
                return this.event;
            }
        },
    },
    mounted() {
        this.$refs.modal.addEventListener('hidden.bs.modal', (e) => {
            if (e.target.id === this.id) {
                this.updated = this.generateUUID();
                if (this.justAutoSaved) {

                    if (this.isNew && !this.savedOnPurpose) {
                        this.$axios.delete(this.savedNoteId);
                    } else {
                        this.$emit('notes-updated');
                    }
                    this.justAutoSaved = false;

                }
            }
        })
        this.$refs.modal.addEventListener('hidePrevented.bs.modal', () => {
            this.hideModal()
        })
    },
    methods: {
        hideModal() {
            if (this.warnNotSaved && !confirm('Are you sure you want to leave? Changes will get lost.')) {
                return
            }
            this.$emit('hide-modal', {reload: this.savedOnPurpose});
        },
        submitForm(doStay) {
            console.log("Submitting....");
            this.doStayAfterSubmit = doStay;
            // console.log("submit form")
            this.$formulate.submit(`${this.updated}`)
            console.log("Submitting end...");
        },
        onSubmitted(args) {
            console.log("onSubmitted....");
            this.loading = false;
            this.savedNoteId = args.id;

            const setEmptyNote = !args.autoSaved && !args.stayAfterSubmit;

            this.justAutoSaved = args.autoSaved;

            if (!args.autoSaved) {
                this.savedOnPurpose = true;
            }

            if (setEmptyNote) {
                this.updated = this.generateUUID();
                this.$emit('notes-updated');
            }

            console.log("onSubmitted end....");
        },
        toggleConfirmModal(open) {
            this.confirmOpen = open;
        }
    }

}
