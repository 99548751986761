
import SaveButton from "../save-button.vue";

export default {
    name: "PitRequestForm",
    components: {SaveButton},
    props: {
        company: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            loading: false,
            title: '',
            description: '',
            companies: [],
            formKey: 'pitRequestFormKey'
        }
    },
    computed: {},
    mounted(){
        if (this.company) {
            this.companies.push(this.company)
        }
    },
    methods: {
        submitForm() {
            this.loading = true;
            this.$axios.post('/api/pit_requests', {
                title: this.title,
                description: this.description,
                companies: this.companies.map(c => c['@id']),
            })
                .then(() => {
                    // Reset Form
                    this.title = '';
                    this.description = '';
                    this.companies = [];

                    this.formKey = this.generateUUID();

                    // Show confirmation
                    const toastId = this.generateUUID();
                    this.addToast({
                        type: "success",
                        title: "Pit Request sent!",
                        message: "Your request has been sent to the pit.",
                        id: toastId,
                    });
                    this.$nextTick(() => {
                        this.toggleToast(toastId);
                    })
                })
                .finally(() => {
                    this.loading = false;
                    if (this.company) this.$emit("sent")
                });
        }
    }
}
