
import moment from "moment";
import Suggestion from "../suggestion";

export default {
    name: "TransactionDataForm",
    components: {Suggestion},
    props: {
        value: {
            type: Object,
            required: true,
        },
        suggestions: {
            type: Object,
            required: true,
        },
    },
    computed: {
        transactionData: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit(val);
            }
        },
        offeringDate: {
            get() {
                if (this.transactionData.offeringDate) {
                    return moment(this.transactionData.offeringDate).format("YYYY-MM-DD");
                } else {
                    return null;
                }
            },
            set(newValue) {
                this.transactionData.offeringDate = newValue;
            }
        },
        closingDate: {
            get() {
                if (this.transactionData.closingDate) {
                    return moment(this.transactionData.closingDate).format("YYYY-MM-DD");
                } else {
                    return null;
                }
            },
            set(newValue) {
                this.transactionData.closingDate = newValue;
            }
        },
        formattedSuggestedOfferingDate: {
            get() {
                if (this.suggestions.offeringDate) {
                    return moment(this.suggestions.offeringDate).format("DD/MM/YYYY");
                } else {
                    return null;
                }
            },
            set() {
                // Do nothing.
            }
        },
        formattedSuggestedClosingDate: {
            get() {
                if (this.suggestions.closingDate) {
                    return moment(this.suggestions.closingDate).format("DD/MM/YYYY");
                } else {
                    return null;
                }
            },
            set() {
                // Do nothing.
            }
        },
        completedByMessage() {
            let message = "Deal Data was completed";
            if (this.transactionData.completedByUser && this.transactionData.completedByUser.nameWithCompany) {
                message += " by " + this.transactionData.completedByUser.nameWithCompany;
            }
            if (this.transactionData.completedAt) {
                message += " at " + this.formatDateTime(this.transactionData.completedAt);
            }
            return message + ".";
        },
    }
}
