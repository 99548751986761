
import {mapGetters} from "vuex";

export default {
    name: "TableActions",
    data() {
        return {
            open: false,
            preventClose: false,
        }
    },
    computed: {
        ...mapGetters({
            globalClick: "app/globalClick"
        }),
    },
    watch: {
        globalClick() {
            if (this.preventClose) {
                this.preventClose = false;
                return;
            }
            this.open = false;
        },
    },
    methods: {
        onClick() {
            this.preventClose = true;
        }
    }
}
