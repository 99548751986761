import { render, staticRenderFns } from "./skeleton-checkbox.vue?vue&type=template&id=0e13d3b0&scoped=true"
import script from "./skeleton-checkbox.vue?vue&type=script&lang=js"
export * from "./skeleton-checkbox.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e13d3b0",
  null
  
)

export default component.exports