

import StockInfoSmall from "../components/stock-info-small.vue";
import SaveButton from "../components/save-button.vue";

export default {
    name: 'OtherSaNews',
    components: {SaveButton, StockInfoSmall},
    props: {
        newsItem: {
            type: Object,
            default: null
        },
        companyForTicker: {
            type: Object,
            default: () => ({})
        },
        addCompanyloadingTicker: {
            type: String,
            default: null
        },
        ignoreLoadingTicker: {
            type: String,
            default: null
        },
        activeType: {
            type: String,
            default: 'all'
        }
    },
    data() {
        return {
            localNewsItem: null,
            localCompanyForTicker: {},
            localAddCompanyloadingTicker: null,
            localIgnoreLoadingTicker: null,
            widestButtonWidth: null,
            tempNewsItem: null,
            localActiveType: this.activeType,
        }
    },
    watch: {
        newsItem: {
            immediate: true,
            handler(newVal) {
                this.localNewsItem = newVal;
                this.localActiveType = this.activeType; // Update localActiveType when newsItem changes
            }
        }
    },
    created() {
        this.localNewsItem = this.newsItem;
        this.localCompanyForTicker = {...this.companyForTicker};
        this.localAddCompanyloadingTicker = this.addCompanyloadingTicker;
        this.localIgnoreLoadingTicker = this.ignoreLoadingTicker;
    },
    methods: {
        // ... your other methods ...
        searchWithGoogleUrl(newsItem) {
            const title = newsItem.subjectParsed.replace(/\(.*?\)/g, '').trim();
            return "https://www.google.com/search?" + this.buildQueryString({"q": title});
        },
        addCompanyToTicker(newsItem, ticker) {
            this.localAddCompanyloadingTicker = ticker;

            this.$axios.post(`/api/street_account_news/${newsItem.id}/add_company_to_ticker`, {
                ticker,
                companyId: this.localCompanyForTicker[ticker].id
            }).then((response) => {
                this.localNewsItem = response.data;
            }).finally(() => {
                this.localAddCompanyloadingTicker = null;
                this.localCompanyForTicker[ticker] = null;
            });
        },
        ignoreTicker(newsItem, ticker) {
            this.localIgnoreLoadingTicker = ticker;

            this.$axios.post(`/api/street_account_news/${newsItem.id}/ignore_ticker`, {
                ticker
            }).then((response) => {
                this.localNewsItem = response.data;
            }).finally(() => {
                this.localIgnoreLoadingTicker = false;
            });
        },
    },
}
