
import {defineComponent} from 'vue'
import moment from "moment/moment";
import TableActions from "../table-actions.vue";
import ContactName from "../contacts/contact-name.vue";
import LoadingSpinner from "../loading-spinner.vue";
import emailPopoverActions from "../../mixins/email-popover-actions";
import modals from "../../mixins/modals";
import ConfirmModal from "../confirm-modal.vue";
import optionButtonTooltip from "../../mixins/option-button-tooltip";
import staffing from "../../mixins/staffing";
import EditStaffingModal from "./edit-staffing-modal.vue";

export default defineComponent({
    name: "StaffingTable",
    components: {ConfirmModal, EditStaffingModal, LoadingSpinner, ContactName, TableActions},
    mixins: [emailPopoverActions, modals, optionButtonTooltip, staffing],
    props: {
        companyId: {
            type: Number,
            default: null,
        },
        companyName: {
            type: String,
            default: null,
        },
        contactId: {
            type: Number,
            default: null,
        },
        contactName: {
            type: String,
            default: null,
        },
        forHistory: {
            type: Boolean,
            default: false,
        },
        updateTableProp: {
            type: Date,
            default: null,
        },
        stacked: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            config: {
                columns: [
                    {
                        headline: "Name",
                        ignoreEmpty: true
                    },
                    {
                        headline: "Level",
                        // hidden: !!this.contactId,
                        hideHeadlineBreakpoint: "all",
                    },
                    {
                        headline: "Staffed for",
                        hidden: !!this.contactId,
                        breakpoint: "all",
                    },
                    {
                        headline: "Role",
                    },
                    {
                        headline: "Job Title",
                    },
                    {
                        headline: "Division",
                    },
                    {
                        headline: "Start",
                        sort: true,
                        hidden: !this.forHistory,
                    }, {
                        headline: "End",
                        sort: true,
                        hidden: !this.forHistory,
                    },
                    {
                        headline: "Confirmed",
                        align: "center",
                        ignoreEmpty: true
                    },
                    {
                        headline: "Actions",
                        align: "end",
                        hideHeadlineBreakpoint: "all",
                        ignoreEmpty: true,
                        hidden: this.forHistory,
                    },
                    {
                        headline: "Description/Nature of Relationship",
                        align: "start",
                        breakpoint: "all",
                    }
                ],
                hideEmptyColumns: true,
                ajaxUrl: `/api/staffing/list?stacked=${this.stacked ? 1 : 0}`
            },
            rowsUpdated: moment().unix(),
            loading: false,
            loadingItem: null,
            staffingToEdit: null,
            modal: null,
            userManagesCompanies: [],
            itemToDelete: null,
            tableUpdate: null,
        }
    },
    computed: {
        filters() {
            return {
                companyIds: this.companyId ? [this.companyId] : null,
                contactIds: this.contactId ? [this.contactId] : null,
                includePast: this.forHistory
            }
        },
        finalTableUpdate() {
            return new Date(Math.max.apply(null, [this.tableUpdate, this.updateTableProp]));
        }
    },
    methods: {
        onTableMounted() {
            setTimeout(() => this.initializeOptionButtonTooltips('table mounted'), 250);
        },
        showEditForm(item) {
            if (item) {
                this.staffingToEdit = item;
            } else {
                this.staffingToEdit = null;
            }
            this.modal = this.openModal(this.$refs.editStaffingModal);
        },
        staffingUpdated() {
            this.closeModal(this.modal);
            this.tableUpdate = new Date();
        },
        resetEditStaffing() {
            this.staffingToEdit = null;
        },
        deleteStaffing(item) {
            console.log("Item", item);
            this.itemToDelete = item;
            this.modal = this.openModal(this.$refs.deleteStaffingModal);
        },
        cancelDeleteStaffing() {
            this.itemToDelete = null;
        },
        doDeleteStaffing(item) {

            let url;
            if (item.hasOwnProperty("userManagesContactRole")) {
                url = `/api/user_manages_contacts/${item.id}/end`;
            } else if (this.companyId) {
                url = `/api/user_manages_companies/${item.id}/end`;
            }

            this.$axios.patch(url, {}, {
                headers: {
                    'Content-Type': 'application/merge-patch+json'
                }
            })
                .then((response) => {
                    const toastId = this.generateUUID();
                    this.addToast({
                        type: "success",
                        title: "Staffing deleted",
                        message: "User was removed from staffing",
                        id: toastId,
                    });
                    this.$nextTick(() => {
                        this.toggleToast(toastId);
                    })
                })
                .finally(() => {
                    this.tableUpdate = new Date();
                });
        },
        async requestDeleteStaffing(item) {
            this.itemToDelete = item;
            await this.$nextTick();
            this.modal = this.openModal(this.$refs.deleteStaffingRequestModal);
        },
        cancelRequestStaffing() {
            this.itemToDelete = null;
            this.modal = null;
        }
    }
})
