
import TableActions from "../table-actions.vue";
import TransactionMoneyRaisedCell from "./transactions-table-cells/transaction-money-raised-cell.vue";
import TransactionDataSourceCell from "./transactions-table-cells/transaction-data-source.cell.vue";

export default {
    name: "DuplicateTransactionsTable",
    components: {TableActions, TransactionDataSourceCell, TransactionMoneyRaisedCell},
    props: {
        rows: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            duplicatesConfig: {
                columns: [
                    {
                        headline: "Announced On",
                        sort: true
                    },
                    {
                        headline: "Pricing Date",
                        sort: true
                    },
                    {
                        headline: "Investment Type",
                        sort: true
                    },
                    {
                        headline: "Transaction Type",
                        sort: true
                    },
                    {
                        headline: "Money Raised",
                        sort: true
                    },
                    {
                        headline: "Data Source",
                        sort: true
                    }, {
                        headline: "Actions",
                        hideHeadlineBreakpoint: "all",
                        align: "end",
                    },
                ]
            },
        };
    },
    methods: {
        removeTransactionAsManualDuplicate(duplicateTransactionId) {
            this.$axios.post(`/api/transactions/remove_manual_duplicate`, {
                transactionIdToRemove: duplicateTransactionId
            }).then(() => {
                const toastId = this.generateUUID();
                this.addToast({
                    type: "success",
                    title: "Deals Updated",
                    message: "The deal was removed as a manual duplicate.",
                    id: toastId,
                });

                this.$nextTick(() => {
                    this.toggleToast(toastId);
                    this.$emit('updated');
                });
            });
        },
    },
}
