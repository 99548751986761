import { render, staticRenderFns } from "./note-reminder-form.vue?vue&type=template&id=d3ebf83a&scoped=true"
import script from "./note-reminder-form.vue?vue&type=script&lang=js"
export * from "./note-reminder-form.vue?vue&type=script&lang=js"
import style0 from "./note-reminder-form.vue?vue&type=style&index=0&id=d3ebf83a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d3ebf83a",
  null
  
)

export default component.exports