

import SaveButton from "../save-button.vue";
import iriPreparation from "../../mixins/iri-preparation";
import relationshipTypDirections, {
    FROM_CONTACT_TO_CONTACT,
    FROM_CONTACT_TO_COMPANY,
    FROM_COMPANY_TO_CONTACT,
    FROM_COMPANY_TO_COMPANY
    } from "../../mixins/relationshipTypDirections";

export default {
    name: "EditRelationshipModal",
    components: {SaveButton},
    mixins: [iriPreparation, relationshipTypDirections],
    props: {
        id: {
            type: String,
            required: false,
            default: "investorTargetsModal"
        },
        relationshipToEdit: {
            type: Object,
            required: true,
        },
        contact: {
            type: Object,
            required: false,
            default: null,
        },
        company: {
            type: Object,
            required: false,
            default: null,
        }
    },
    data() {
        return {
            relationshipTypes: [],
            relationshipWorkingCopy: null,
            loading: false,
            FROM_CONTACT_TO_CONTACT,
            FROM_CONTACT_TO_COMPANY,
            FROM_COMPANY_TO_CONTACT,
            FROM_COMPANY_TO_COMPANY,
        }
    },
    computed: {
        relationshipTypesForSelect() {
            if (this.contact) {
                return this.relationshipTypes.filter((relationshipType) => {
                    return this.isWithContact(relationshipType?.relationshipTypeDirection)
                })
            } else if (this.company) {
                return this.relationshipTypes.filter((relationshipType) => {
                    return this.isWithCompany(relationshipType?.relationshipTypeDirection)
                })
            } else {
                return this.relationshipTypes;
            }
        }
    },
    watch: {
        relationshipToEdit: {
            handler(newVal, oldVal) {
                this.relationshipWorkingCopy = structuredClone(newVal);
            },
            immediate: true
        },
        "relationshipWorkingCopy.relationshipType": {
            handler(newVal, oldVal) {
                if (this.contact && newVal) {
                    // We have a contact, let's check if there is only one contact field for the current contact type
                    if(newVal.relationshipTypeDirection === FROM_COMPANY_TO_CONTACT && !this.relationshipWorkingCopy.toContact) {
                        this.relationshipWorkingCopy.toContact = this.contact;
                    } else if(newVal.relationshipTypeDirection === FROM_CONTACT_TO_COMPANY && !this.relationshipWorkingCopy.fromContact) {
                        this.relationshipWorkingCopy.fromContact = this.contact;
                    }
                } else if(this.company && newVal) {
                    // We have a company, let's check if there is only one company field for the current contact type
                    if(newVal.relationshipTypeDirection === FROM_CONTACT_TO_COMPANY && !this.relationshipWorkingCopy.toCompany) {
                        this.relationshipWorkingCopy.toCompany = this.company;
                    } else if(newVal.relationshipTypeDirection === FROM_COMPANY_TO_CONTACT && !this.relationshipWorkingCopy.fromCompany) {
                        this.relationshipWorkingCopy.fromCompany = this.company;
                    }
                }
            },
            deep: true
        }
    },
    mounted() {
        this.$refs.modal.addEventListener('shown.bs.modal', () => {
            this.loadRelationshipTypes();
        });
    },
    methods: {
        loadRelationshipTypes() {
            if (this.relationshipTypes.length > 0) {
                return;
            }

            this.$axios.get('/api/relationship_types')
                .then((response) => {
                    this.relationshipTypes = response.data['hydra:member'];
                })
                .catch(error => {
                    console.error(error);
                });
        },
        submitForm() {
            this.loading = true;
            let relationshipToSubmit = structuredClone(this.relationshipWorkingCopy);
            relationshipToSubmit = this.prepareIri(relationshipToSubmit);
            let promise = null;
            if (relationshipToSubmit.id) {
                promise = this.$axios.patch(`/api/relationships/${relationshipToSubmit.id}`, relationshipToSubmit, {
                    headers: {
                        'Content-Type': 'application/merge-patch+json'
                    }
                });
            } else {
                promise = this.$axios.post(`/api/relationships`, relationshipToSubmit);
            }
            promise
                .then((response) => {
                    this.loading = false;
                    const toastId = this.generateUUID();
                    this.addToast({
                        type: "success",
                        title: "Success",
                        message: "Relationship added successfully!",
                        id: toastId,
                    })
                    this.$nextTick(() => {
                        this.toggleToast(toastId);
                        this.$emit('relationship-updated', response.data);
                    });
                })
                .catch(error => {
                    console.error(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }
}
