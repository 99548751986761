
import Masonry from "masonry-layout";
import ConfirmModal from "../confirm-modal";
import modals from "../../mixins/modals";
import ShowNoteModal from "./show-note-modal";
import EditNoteModal from "./edit-note-modal";
import Note from "./note";

export default {
    name: "NotesGrid",
    components: {Note, ConfirmModal, EditNoteModal, ShowNoteModal},
    mixins: [modals],
    props: {
        type: {
            type: String,
            default: "COMPANY",
            validator: function validator(value) {
                return ["COMPANY", "CONTACT", "EVENT", "PROJECT", "ALL"].includes(value);
            }
        },
        noteEntries: {
            type: Array,
            required: true
        },
        singleColumn: {
            type: Boolean,
            default: false
        },
        showCompanyNotes: {
            type: Boolean,
            default: true,
        },
        showContactNotes: {
            type: Boolean,
            default: true,
        },
        showEventNotes: {
            type: Boolean,
            default: true,
        },
        showProjectNotes: {
            type: Boolean,
            default: true,
        },
        onlyMyNotes: {
            type: Boolean,
            default: false,
        },
        customEdit: {
            type: Boolean,
            default: false
        },
        showSelect: {
            type: Boolean,
            default: true
        },
        allSelected: {
            type: [Boolean, Number],
            default: false
        },
        listInteractionParticipants: {
            type: Boolean,
            default: true
        },
        noteIdToShow: {
            type: Number,
            default: null,
        },
        forTargeting: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            noteToEdit: null,
            noteToDelete: null,
            modal: null,
            masonryNotes: null,
            masonryFeedbacks: null,
            masonryTimeout: null,
            masonryInitialized: false,
            selectedNotes: {},
        }
    },
    computed: {
        notes() {
            return this.noteEntries.filter(note => note.noteTypeTitleSlug !== 'feedback').sort(this.noteSorter);
        },
        feedbacks() {
            return this.noteEntries.filter(note => note.noteTypeTitleSlug === 'feedback').sort(this.noteSorter);
        },
        noteKey() {
            return JSON.stringify(this.noteToEdit);
        },
        noteToEditType() {
            if (this.type !== "ALL") {
                return this.type;
            } else if (this.noteToEdit) {
                if (this.noteToEdit.company) {
                    return "COMPANY"
                } else if (this.noteToEdit.contact) {
                    return "CONTACT"
                } else if (this.noteToEdit.project) {
                    return "PROJECT"
                }
            }
            return "DEFAULT"
        },
    },
    watch: {
        noteEntries() {
            if (this.masonryInitialized) {
                this.rebuildMasonry();
            } else {
                this.buildMasonry();
            }
        },
        noteKey(val) {
            if (val) {
                this.$nextTick(() => {
                    // Edit Modal
                    this.$refs.editNoteModal.$el.addEventListener('hidden.bs.modal', () => {
                        this.$emit('modal-close');
                    });
                    this.$refs.editNoteModal.$el.addEventListener('shown.bs.modal', () => {
                        this.$emit('modal-open');
                    });

                    // Show modal
                    this.$refs.showNoteModal.$el.addEventListener('hidden.bs.modal', () => {
                        this.$emit('modal-close');
                    })
                    this.$refs.showNoteModal.$el.addEventListener('shown.bs.modal', () => {
                        this.$emit('modal-open');
                    })

                });
            }
        }, selectedNotes: {
            handler() {
                this.$nextTick(() => {
                    this.$emit("notes-selected", this.selectedNotes);
                })
            },
            deep: true,
            immediate: true
        },
        allSelected: {
            handler(val) {
                if (val && val === 2) {
                    this.selectedNotes = Object.fromEntries(this.noteEntries.map(x => [x.id, true]));
                } else if (!val) {
                    this.selectedNotes = {}
                }
            },
            immediate: true
        }
    },
    async created() {
        if (this.noteIdToShow) {
            // Edit a specific ID => load it, put it into the view modal
            const response = await this.$axios.get(`/api/notes/${this.noteIdToShow}`);
            this.showNote(response.data);
        }
    },
    mounted() {
        if (this.masonryInitialized) {
            this.rebuildMasonry();
        } else {
            this.buildMasonry()
        }

        this.$refs.showNoteModal.$el.addEventListener('hidden.bs.modal', () => {
            this.$emit('modal-close')
        })

        this.$refs.showNoteModal.$el.addEventListener('shown.bs.modal', () => {
            this.$emit('modal-open')
        })
    },
    methods: {
        noteSelected(data) {
            this.$set(this.selectedNotes, data.id, data.value);
        },

        noteSorter(a, b) {
            if (a.date > b.date) {
                return -1;
            }
            if (a.date < b.date) {
                return 1;
            }
            return 0
        },
        buildMasonry() {
            // eslint-disable-next-line no-new
            this.masonryNotes = new Masonry(this.$refs.masonryNoteGrid, {
                // options
                percentPosition: true,
                gutter: 0,
            });
            this.masonryFeedbacks = new Masonry(this.$refs.masonryFeedbackGrid, {
                // options
                percentPosition: true,
                gutter: 0,
            });
            this.masonryInitialized = true;
        },
        rebuildMasonry() {
            if (!this.masonryNotes) {
                return;
            }
            clearTimeout(this.masonryTimeout);
            this.masonryTimeout = setTimeout(() => {
                this.masonryNotes.reloadItems();
                this.masonryNotes.layout();
                this.masonryFeedbacks.reloadItems();
                this.masonryFeedbacks.layout();
            }, 250)
        },
        editNote(note) {
            if (this.customEdit) {
                this.$emit("edit", note)
            } else {
                this.noteToEdit = Object.assign({}, note);
                this.$nextTick(this.openEditModal);
            }
        },
        onNoteClick(note) {
            if (!this.customEdit) {
                return
            }
            this.editNote(note)
        },
        showNote(note) {
            this.noteToEdit = Object.assign({}, note);
            this.$nextTick(this.openShowModal);
        },
        openEditModal() {
            this.modal = this.openModal(this.$refs.editNoteModal, {
                backdrop: "static",
                keyboard: false,
            });
            this.$emit("modal-open");
        },
        openShowModal() {
            this.modal = this.openModal(this.$refs.showNoteModal);
            this.$emit("modal-open");

        },
        notesUpdated() {
            this.$emit("updated")
            this.closeModal(this.modal);
        },

        deleteNote(item) {
            this.noteToDelete = item;

            if (this.modal) { // if there is a opened modal....
                this.closeModal(this.modal);
            }

            // eslint-disable-next-line no-constant-condition
            if (this.isAdminUser) {
                this.modal = this.openModal(this.$refs.adminDeleteNoteModal);
            } else {
                this.modal = this.openModal(this.$refs.softDeleteNoteModal);
            }

        },

        doSoftDeleteNote(item) {
            this.$axios.post("/api/notes/soft_delete/" + item.id)
                .then((response) => {
                    this.itemToDelete = null;
                    const toastId = this.generateUUID();
                    this.addToast({
                        type: "success",
                        title: "Deleted",
                        message: response.data.message,
                        id: toastId,
                    });
                    this.$nextTick(() => {
                        this.toggleToast(toastId);
                    })

                }).finally(() => {
                    this.$emit("updated");
                })
        },

        restoreNote(item) {
            this.$axios.post("/api/notes/restore/" + item.id)
                .then((response) => {
                    const toastId = this.generateUUID();
                    this.addToast({
                        type: "success",
                        title: "Restored",
                        message: response.data.message,
                        id: toastId,
                    });
                    this.$nextTick(() => {
                        this.toggleToast(toastId);
                    })
                }).finally(() => {
                    this.$emit("updated");
                })
        },

        doDeleteNote(item) {
            this.$axios.delete(`/api/notes/${item.id}`)
                .then(() => {
                    this.itemToDelete = null;

                    const toastId = this.generateUUID();
                    this.addToast({
                        type: "success",
                        title: "Deleted",
                        message: "Note deleted!",
                        id: toastId,
                    });
                    this.$nextTick(() => {
                        this.toggleToast(toastId);
                    })

                }).finally(() => {
                    this.$emit("updated");
                })
        },

        cancelDeleteNote() {
            this.$nextTick(() => {
                this.itemToDelete = null;
            })
        },

    }
}
