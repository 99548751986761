
export default {
    name: "PdfViewer",
    components: {},
    props: {
        pdfUrl: {
            type: String,
            required: true
        },
        scale: {
            type: Number,
            default: 0.75
        },
        disabled: {
            type: Boolean,
            default: false
        },
        initialPosition: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            canvas: null,
            pdfjsLib: null,
            pdf: null,
            numPages: null,
            currentPage: 1,
            position: null,
            pdfWidth: '100%',
            canvasId: null,
            viewerKey: null,
        }
    },
    computed: {},
    watch: {
        currentPage: {
            handler(val) {
                this.renderPDF()
            },
            immediate: true
        },
        initialPosition: {
            handler(val) {
                if (val === null) {
                    return;
                }
                this.position = val;
                this.currentPage = val.page;
                // this.drawX(val.coordinates.x, val.coordinates.y);
            },
            immediate: true
        }
    },
    async mounted() {
        this.canvasId = 'canvas-' + Math.random().toString(36).substr(2, 9);
        this.viewerKey = Math.random().toString(36).substr(2, 9);

        await this.$nextTick();

        const {pdfjsLib} = globalThis;
        pdfjsLib.GlobalWorkerOptions.workerSrc = '/engine/js/pdf.worker.mjs.js';
        this.pdfjsLib = pdfjsLib;

        // header on that server.
        const url = this.pdfUrl;

        // Asynchronous download of PDF
        const loadingTask = pdfjsLib.getDocument(url);
        loadingTask.promise.then(async (pdf) => {
            this.numPages = pdf.numPages;
            this.pdf = pdf;

            await this.renderPDF();
        }, (reason) => {
            // PDF loading error
            console.error(reason);
        });

        this.canvas = document.querySelector('#' + this.canvasId);
        this.canvas.addEventListener('mousedown', (e) => {
            this.updatePosition(e)
        })
    },
    methods: {
        renderPDF() {
            if (!this.pdf) {
                return;
            }

            return new Promise((resolve, reject) => {
                this.pdf.getPage(this.currentPage).then((page) => {

                    const scale = this.scale;
                    const viewport = page.getViewport({scale});

                    // Prepare canvas using PDF page dimensions
                    const canvas = document.getElementById(this.canvasId);
                    const context = canvas.getContext('2d');
                    canvas.height = viewport.height;
                    canvas.width = viewport.width;
                    this.pdfWidth = viewport.width + 'px';

                    // Render PDF page into canvas context
                    const renderContext = {
                        canvasContext: context,
                        viewport
                    };
                    const renderTask = page.render(renderContext);
                    renderTask.promise.then(() => {
                        if (this.position !== null && this.position.page === this.currentPage) {
                            this.drawX(this.position.coordinates.x, this.position.coordinates.y);
                        }
                        resolve(); // Resolve the promise once rendering is complete
                    }).catch(error => {
                        reject(error); // Reject the promise in case of an error
                    });
                }).catch(error => {
                    reject(error); // Reject the promise in case of an error
                });
            });
        },
        async updatePosition(event) {
            if (this.disabled) {
                return;
            }

            this.position = null;
            await this.renderPDF();

            const rect = this.canvas.getBoundingClientRect()
            const x = (event.clientX - rect.left) / this.scale;
            const y = (event.clientY - rect.top) / this.scale;
            this.position = {
                page: this.currentPage,
                coordinates: {x, y}
            };
            this.$emit('position', this.position);
            this.drawX(x, y);
        },
        drawX(x, y) {
            x = x * this.scale;
            y = y * this.scale;
            const ctx = this.canvas.getContext('2d');
            ctx.lineWidth = 2;
            ctx.strokeStyle = '#004668';
            ctx.beginPath();
            ctx.moveTo(x - 10, y - 10);
            ctx.lineTo(x + 10, y + 10);
            ctx.moveTo(x + 10, y - 10);
            ctx.lineTo(x - 10, y + 10);
            ctx.stroke();
        }

    }
}
