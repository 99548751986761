
import moment from "moment";
import SaveButton from "../save-button";
import TransactionDataForm from "./transaction-data-form";
import iriPreparation from '~/mixins/iri-preparation'

export default {
    name: "EditTransactionDataModal",
    components: {TransactionDataForm, SaveButton},
    mixins: [iriPreparation],
    props: {
        id: {
            type: String,
            default: "editTransactionDataModal"
        },
        transactionData: {
            type: Object,
            required: true,
        },
        suggestions: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            transactionDataWorkingCopy: {},
            completedByUserName: null,
            loading: false,
        }
    },
    computed: {
        updateUrl() {
            return this.transactionDataWorkingCopy["@id"];
        },
        isNew() {
            return !(this.transactionDataWorkingCopy && this.transactionDataWorkingCopy["@id"]);
        },
    },
    watch: {
        transactionData: {
            handler() {
                this.resetWorkingCopy();
            },
            deep: true,
            immediate: true,
        }
    },
    methods: {
        resetWorkingCopy() {
            this.transactionDataWorkingCopy = Object.assign({}, this.transactionData);
        },
        saveAndMarkAsComplete() {
            this.transactionDataWorkingCopy.isCompleted = true;
            this.transactionDataWorkingCopy.completedByUser = `/api/users/${this.userInfo.id}`;
            this.transactionDataWorkingCopy.completedAt = moment().format("YYYY-MM-DD HH:mm:ss");
            this.save();
        },
        save() {
            this.loading = true;

            const finalTransactionData = this.prepareIri(this.transactionDataWorkingCopy);

            if (this.isNew) {
                this.$axios.post('/api/transaction_datas', finalTransactionData).then((response) => {
                    this.$emit('transaction-data-updated');
                }).finally(() => {
                    this.loading = false;
                });

            } else {
                this.$axios.patch(this.updateUrl, finalTransactionData, {
                    headers: {
                        'Content-Type': 'application/merge-patch+json'
                    }
                }).then((response) => {
                    this.$emit('transaction-data-updated');
                }).finally(() => {
                    this.loading = false;
                });
            }
        },
    }
}
