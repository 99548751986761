


import SaveButton from "../save-button";

export default {
    name: "AddUserModal",
    components: {SaveButton},
    props: {
        id: {
            type: String,
            default: "addUserModal"
        }
    },
    data() {
        return {
            contact: null,
            password1: null,
            password2: null,
            loading: false,
        }
    },
    computed: {
        passwordsValid() {
            return this.password1 && (this.password1.length >= 8) && (this.password1 === this.password2);
        },
    },
    watch: {},
    mounted() {
        this.$refs.modal.addEventListener('show.bs.modal', () => {

        })
    },
    created() {

    },
    methods: {
        submitForm() {
            this.loading = true;
            this.$axios.post('/api/user/prepare', {
                contactId: this.contact.id,
                password1: this.password1,
                password2: this.password2
            })
                .then((response) => {
                    this.$router.push('/user/edit/' + response.data.id);
                });

        }
    }
}
