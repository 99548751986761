


import ParticipantsAndCompanies from "../interactions/participants-and-companies";
import EditingHistory from "~/components/editing-history";
import optionButtonTooltip from "~/mixins/option-button-tooltip";

export default {
    name: "ShowNoteModal",
    components: {ParticipantsAndCompanies, EditingHistory},
    mixins: [optionButtonTooltip],
    props: {
        note: {
            type: Object,
            default: null,
        },
        id: {
            type: String,
            default: "showNoteModal"
        },
        allowEditDelete: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            imageExtensions: ['png', 'gif', 'jpg', 'jpeg'],
            showAutoSavedHistories: false,
        }
    },
    computed: {
        canEdit() {
            return this.userInfo && this.note.user.id === this.userInfo.id || !this.note.private && this.note.othersCanEdit;
        },
        media() {
            return this.images.map((img) => {
                return { // For an image
                    type: 'image', // Can be omitted for image
                    thumb: img.fileUrl,
                    src: img.fileUrl,
                    caption: '', // Optional
                    srcset: null // Optional for displaying responsive images
                }
            })
        },
        images() {
            return this.note && this.note.noteAttachments ? this.note.noteAttachments.filter((x) => {
                const extension = x.filename.split('.').pop()

                return this.imageExtensions.includes(extension)

            }) : []
        },
        files() {
            return this.note && this.note.noteAttachments ? this.note.noteAttachments.filter((x) => {
                const extension = x.filename.split('.').pop()

                return !this.imageExtensions.includes(extension)

            }) : []
        },
        historyEntries() {
            if (!this.showAutoSavedHistories) {
                return this.note.editHistories.filter(x => !x.autoSaved);
            }
            return this.note.editHistories;
        },
    },
    methods: {
        openLightbox(index) {
            this.$refs.lightbox.showImage(index)
        },
        closeModal() {
            this.$emit('hide-modal', {reload: false});
        },

    },

}
