

import LoadingSpinner from "../loading-spinner";

export default {
    name: "UserCoverageModal",
    components: {LoadingSpinner},
    props: {
        userId: {
            type: Number,
            default: null,
        },
        id: {
            type: String,
            default: "userCoverageModal"
        }
    },
    data() {
        return {
            isLoading: false,
            config: {
                columns: [
                    {
                        headline: "Company",
                        sort: true,
                    }, {
                        headline: "Ticker",
                        sort: true,
                    }, {
                        headline: "Cov. Analysts",
                        sort: true,
                    }, {
                        headline: "Rating",
                        sort: true,
                    }, {
                        headline: "Price Target",
                        align: "end",
                    }, {
                        headline: "Price",
                        align: "end",
                    }
                ],
                pagination: 25,
            },
            userManagesCompanies: [],
            stockInfoByTicker: {},
            researchReportsByCompanyId: {},
            sortOrder: [{
                index: 0,
                asc: true,
            }],
        }
    },
    computed: {
        rows() {
            return this.userManagesCompanies.map((umc) => {
                const researchReport = this.researchReportsByCompanyId[umc.company.id];

                return [
                    {
                        type: "company",
                        company: umc.company,
                        sortValue: umc.company.name,
                    },
                    {
                        text: umc.company.ticker,
                        sortValue: umc.company.ticker,
                    },
                    {
                        type: 'analyst',
                        analysts: umc.company.analysts
                    },
                    {
                        type: "rating",
                        rating: researchReport ? researchReport.rating : null,
                        sortValue: researchReport ? researchReport.rating : "",
                    },
                    {
                        type: "priceTarget",
                        priceTarget: researchReport ? researchReport.priceTarget : null,
                        currency: researchReport ? researchReport.currency : null,
                    },
                    {
                        type: "price",
                        stockInfo: this.stockInfoByTicker[umc.company.ticker],
                    }
                ];
            })
        },
        userName() {
            if (this.userManagesCompanies.length > 0) {
                return this.userManagesCompanies[0].user.name;
            } else {
                return null;
            }
        }
    },
    watch: {},
    mounted() {
        this.$refs.modal.addEventListener('show.bs.modal', () => {
            if (this.userId > 0) {
                this.loadUserCoverages(this.userId);
            }
        })
    },
    created() {

    },
    methods: {
        loadUserCoverages(userId) {
            this.isLoading = true;
            const url = '/api/research_coverage/' + userId;
            this.$axios.get(url)
                .then((response) => {
                    this.userManagesCompanies = response.data.userManagesCompanies;
                    this.stockInfoByTicker = response.data.stockInfoByTicker;
                    this.researchReportsByCompanyId = response.data.researchReportsByCompanyId;
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        downloadResearchCoverage() {
            window.open(
                this.$axios.defaults.baseURL
                    + "/api/contacts/excel_export/research_coverage/" + this.userId,
                '_blank'
            ).focus();
        }
    }
}
