import {Line} from 'vue-chartjs'

export default {
    extends: Line,
    props: {
        chartData: {
            type: Object,
            default: null
        },
        options: {
            type: Object,
            default: null
        }
    },
    mounted() {
        this.updateChart();
    },
    methods: {
        updateChart() {
            this.renderChart(this.chartData, this.options);
        }
    },
    computed: {},
    watch: {
        /*
        chartKey: {
            handler() {
                this.renderChart(this.chartdata, this.options);
            },
            deep: true,
        } */
    }
}
