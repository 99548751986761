
export default {
    name: "SkeletonInline",
    props:{
        width:{
            type:Number,
            default:200,
        },
        height:{
            type:Number,
            default:15,
        }
    }
}
