
export default {
    name: "SkeletonLines",
    props:{
        lines:{
            type:Number,
            default:5,
        },
        height:{
            type:Number,
            default:20
        },
        pattern:{
            type: Array,
            default:()=>{
                return [
                    "col-6","col-6"
                ]
            }
        }
    }
}
