
import flatPickr from 'vue-flatpickr-component';
import LoadingSpinner from "../loading-spinner.vue";
import TableActions from "../table-actions.vue";
import ContactName from "../contacts/contact-name.vue";
import fileDownload from "../../mixins/file-download";
import QuintableSearchInput from "../helpers/quintable-search-input.vue";
import TableFilters from "../table-filters.vue";
import 'flatpickr/dist/flatpickr.css';
import modals from "../../mixins/modals";
import optionButtonTooltip from "../../mixins/option-button-tooltip";
import ResearchReportReadershipBulkOperationModal from "./research-report-readership-bulk-modal.vue";

export default {
    name: "ResearchReportReadershipTable",
    components: {
        ResearchReportReadershipBulkOperationModal,
        TableFilters, QuintableSearchInput, LoadingSpinner, TableActions, ContactName, flatPickr
    },
    mixins: [fileDownload, modals, optionButtonTooltip],
    props: {
        reportId: {
            type: Number,
            default: null,
        },
    },
    data() {
        return {
            isContactImportLoading: null,
            readershipTableUpdated: null,
            searchQuery: null,
            filterOptions: {},
            selectedTiers: null,
            selectedChannels: null,
            selectedReports: [],
            selectedContacts: [],
            selectedCompanies: [],
            dateRange: null,
            dateConfig: {
                mode: 'range',
                altInput: true,
                altFormat: "m/d/Y",
                dateFormat: 'Y-m-d',
            },
            preSelectedRows: [],
            selectedRows: [],
            rows: [],
            selectedIdBackUp: [],
            preSelectedRowIds: [],
            pageChanged: false,
            ajaxRows: [],
            ajaxAll: null,
            bulkData: null,
            bulkModalOpen: false,
        }
    },
    computed: {
        config() {
            return {
                pagination: 50,
                ajaxUrl: `/api/research_reports/readership/list`,
                search: true,
                columns: [
                    {
                        headline: "Report",
                        hidden: this.reportId !== null,
                    },
                    {
                        headline: "Report Date",
                        sort: true,
                        hidden: this.reportId !== null,
                    },
                    {
                        headline: "Company",
                        hidden: this.reportId !== null,
                    },
                    {
                        headline: "Contact",
                    },
                    {
                        headline: "Tier",
                    },
                    {
                        headline: "Channel",
                        breakpoint: "md",
                    },
                    {
                        headline: "Device",
                        breakpoint: "lg"
                    },
                    {
                        headline: "Read Date",
                        sort: true
                    },
                    {
                        headline: "Actions",
                        hideHeadlineBreakpoint: "all",
                        align: 'end'
                    }
                ],
                select: true,
                prettySelect: true,
                selectPosition: "pre",
            }
        },
        filters() {
            let from = null;
            let to = null;
            if (this.dateRange) {
                [from, to = from] = this.dateRange.split(" to ");
            }

            return {
                tiers: this.selectedTiers,
                channels: this.selectedChannels,
                researchReports: this.reportId ? [this.reportId] : this.selectedReports.map(r => r.id),
                contacts: this.selectedContacts.map(c => c.id),
                companies: this.selectedCompanies.map(c => c.id),
                from,
                to
            }
        }
    },
    watch: {
        filters() {
            this.pageChanged = true;
        },
        selectedRows(rows) {
            if (!this.pageChanged && this.ajaxRows) {
                for (let i = 0; i < rows.length; i++) {
                    if (!this.preSelectedRowIds.includes(rows[i].readership_od)) {
                        this.preSelectedRowIds.push(rows[i].readership_od);
                    }
                }

                for (let j = 0; j < this.ajaxRows.length; j++) {
                    const id = this.ajaxRows[j].readership_od;

                    const index = this.preSelectedRowIds.indexOf(id);

                    if (!rows.map(r => r.readership_od).includes(id) && index !== -1) {
                        this.preSelectedRowIds.splice(index, 1);
                    }
                }
            }

            const selectedRowIds = this.selectedRows.map((row) => {
                return row.readership_od
            })
            this.bulkFilters = {
                ids: selectedRowIds
            }
        },
        selectedContactIds() {
            this.contactBulkFilters = this.selectedContactIds
        }
    },
    mounted() {
        this.loadFilterOptions();
    },
    methods: {
        importContact(readershipId) {
            if (this.isContactImportLoading) {
                return;
            }
            this.isContactImportLoading = readershipId;

            this.$axios.post('/api/research_reports/readership/import-contact', {ids: [readershipId]})
                .then((respnse) => {
                    const toastId = this.generateUUID();
                    this.addToast({
                        type: "success",
                        title: "Success",
                        message: respnse.data.message,
                        id: toastId,
                    })
                    this.$nextTick(() => {
                        this.toggleToast(toastId);
                    });
                })
                .finally(() => {
                    this.isContactImportLoading = null;
                    this.readershipTableUpdated = new Date();
                })
        },
        downloadReadershipInfo() {
            const query = {
                search: this.searchQuery,
                sort: this.sortOrder,
                filters: this.filters,
            }

            this.saveQueryAndExport(query, `/api/research_report/readership/excel_export`);
        },
        updateSearchQuery(searchQuery) {
            this.searchQuery = searchQuery;
            this.pageChanged = true;
        },
        updateSortOrder(sortOrder) {
            this.sortOrder = sortOrder;
            this.pageChanged = true;
        },
        resetFilters() {
            this.selectedTiers = null;
            this.selectedChannels = null;
            this.selectedReports = [];
            this.selectedContacts = [];
            this.selectedCompanies = [];
            this.dateRange = null;
            this.$refs.readershipTable.setSearchQuery("");
            this.updateSearchQuery("");
        },
        loadFilterOptions() {
            this.$axios.get("/api/research_reports/readership/filter_options")
                .then((response) => {
                    this.filterOptions = response.data;
                });
        },
        onRowsUpdated(data) {
            this.pageChanged = false;
            this.ajaxRows = data.rows;
            this.ajaxAll = data.all;

            this.preSelectedRows = JSON.parse(JSON.stringify(this.preSelectedRowIds)).map(id => {
                return {
                    key: "readership_id",
                    value: id
                }
            });
            setTimeout(() => this.initializeOptionButtonTooltips('rows updated'), 250);
        },
        clearPreSelection() {
            this.preSelectedRows = [];
            this.selectedRows = [];
        },
        onPageChange() {
            this.pageChanged = true;
        },
        async openBulkOperationModal(mode) {
            if (mode === 'selected') {
                await this.$axios.post('/api/research_report/readership/prepare_hash', {
                    ids: this.selectedRows.map(r => r.readership_id),
                }).then((response) => {
                    this.bulkData = response.data;
                });
            } else {
                if (this.ajaxAll === null || this.ajaxAll > 200) {
                    alert("Bulk Operations can only performed with 200 items max");
                }
                await this.$axios.post('/api/research_report/readership/prepare_hash', {
                    filters: this.filters,
                }).then((response) => {
                    this.bulkData = response.data;
                });
            }
            this.$nextTick(() => {
                this.bulkModalOpen = true;
                this.bulkModal = this.openModal(this.$refs.bulkOperationModal, {
                    backdrop: false
                })
            })
        },
        onBulkPerformed() {
            this.closeModal(this.bulkModal);
            this.bulkModalOpen = false;
            this.bulkData = null;
            this.readershipTableUpdated = new Date();
        }
    }
}
