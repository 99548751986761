
export default {
    name: "Pagination",
    props: {
        initialPageRange: {
            type: Number,
            default: 5,

        },
        totalItems: {
            type: Number,
            default: 0,
        },
        perPage: {
            type: Number,
            default: 20,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            pageOffset: 0,
            currentPage: 1,
        }
    },
    computed: {
        pages() {
            return Math.ceil(this.totalItems / this.perPage);
        },
        pageRange() {
            return Math.min(this.initialPageRange, this.pages);
        },
        visiblePages() {
            const pages = [];
            let start = 0;

            if (this.pages < this.pageRange || this.currentPage === 1) {
                start = 1;
            } else if (this.currentPage === this.pages) {
                start = this.currentPage - (this.pageRange - 1);
            } else {
                let off;
                if (this.pageRange % 2 === 0) {
                    off = this.pageRange / 2;
                } else {
                    off = (this.pageRange - 1) / 2;
                    if (this.currentPage + off > this.pages) {
                        off++;
                    }
                }

                start = this.currentPage - off;
            }

            start = Math.max(start + this.pageOffset, 1);

            for (let i = 0; i < this.pageRange; i++) {
                if (i + start > this.pages) {
                    break;
                }
                pages.push(i + start);
            }

            return pages;
        },
    },
    watch: {
        currentPage() {
            this.pageOffset = 0;
            this.$emit("pageChange", this.currentPage);
        },
    },
    methods: {
        gotoPage(page) {
            if (page === "prev") {
                if (this.currentPage - 1 > 0) {
                    this.currentPage--;
                }
            } else if (page === "next") {
                if (this.currentPage + 1 <= this.pages) {
                    this.currentPage++;
                }
            } else if (page === "first") {
                this.currentPage = 1;
            } else if (page === "last") {
                this.currentPage = this.pages;
            } else {
                this.currentPage = page;
            }
        },
        updatePageOffset(factor) {
            const result = this.pageOffset + this.pageRange * factor;

            if (factor > 0) {
                const offset = Math.min(result, this.pages - this.pageRange);
                this.pageOffset = Math.min(offset, this.pages - this.currentPage);
            } else if (factor < 0) {
                this.pageOffset = Math.max(result, -(this.pages - this.pageRange));
            } else {
                this.pageOffset = 0;
            }
        },
    }
}
