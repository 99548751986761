import { render, staticRenderFns } from "./note-form.vue?vue&type=template&id=376655bd&scoped=true"
import script from "./note-form.vue?vue&type=script&lang=js"
export * from "./note-form.vue?vue&type=script&lang=js"
import style0 from "./note-form.vue?vue&type=style&index=0&id=376655bd&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "376655bd",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoadingSpinner: require('/codebuild/output/src214552897/src/web/components/loading-spinner.vue').default,UploadDropZone: require('/codebuild/output/src214552897/src/web/components/upload-drop-zone.vue').default,InfoText: require('/codebuild/output/src214552897/src/web/components/info-text.vue').default,PCheckIcon: require('/codebuild/output/src214552897/src/web/components/p-check-icon.vue').default,ConfirmModal: require('/codebuild/output/src214552897/src/web/components/confirm-modal.vue').default})
