
export default {
    name: "Suggestion",
    props: {
        currentValue: {
            type: [String, Number],
            required: false,
            default: null,
        },
        suggestedValue: {
            type: [String, Number],
            required: false,
            default: null,
        },
        label: {
            type: String,
            required: false,
            default: "Suggestion: "
        }
    },
}
