
import modals from "../../mixins/modals";
import TableActions from "../table-actions.vue";
import EditRelationshipModal from "./edit-relationship-modal.vue";

export default {
    name: "RelationshipsCard",
    components: {TableActions, EditRelationshipModal},
    mixins: [modals],
    props: {
        contact: {
            type: Object,
            default: null,
        },
        company: {
            type: Object,
            default: null,
        }
    },
    data() {
        return {
            relationships: [],
            config: {
                columns: [
                    {
                        headline: "Title",
                        hideHeadlineBreakpoint: "all",
                    },
                    {
                        headline: "Actions",
                        hideHeadlineBreakpoint: "all",
                    }
                ],
                pagination: 5
            },
            modal: null,
            relationshipToEdit: null,
            runningActionForId: null,
        }
    },
    computed: {
        relationshipRows() {
            return this.relationships.map(relationship => {
                const fromMe = (this.company && relationship.title.fromCompanyId === this.company.id) || (this.contact && relationship.title.fromContactId === this.contact.id);
                return {
                    id: relationship.id,
                    cells: [
                        {
                            title: relationship.title.type,
                            from: relationship.fromCompany ? relationship.fromCompany : relationship.fromContact,
                            to: relationship.toCompany ? relationship.toCompany : relationship.toContact,
                            fromMe,
                            link: (fromMe ? relationship.toCompany : relationship.fromCompany) ? '/companies/' : '/contacts/',
                            type: 'title',
                        },
                        {
                            id: relationship.id,
                            type: 'actions',
                        }
                    ]
                }
            })
        }
    },
    watch: {
        contact: {
            handler(newValue) {
                if (newValue) {
                    this.relationships = structuredClone(this.contact.relationships);
                }
            },
            immediate: true,
            deep: true
        },
        company: {
            handler(newValue) {
                if (newValue) {
                    this.relationships = structuredClone(this.company.relationships);
                }
            },
            immediate: true,
            deep: true
        }
    },
    methods: {
        addRelationship() {
            this.$axios.get('/api/relationships/template')
                .then(async (response) => {
                    this.relationshipToEdit = response.data;
                    await this.$nextTick();
                    this.modal = this.openModal(this.$refs.editRelationshipModal);
                })
        },
        relationshipUpdated() {
            if (this.modal) {
                this.closeModal(this.modal);
            }
            this.$emit('relationship-updated');
        },
        editRelationship(relationshipId) {
            this.runningActionForId = relationshipId;
            this.$axios.get(`/api/relationships/${relationshipId}`)
                .then(async (response) => {
                    this.relationshipToEdit = response.data;
                    await this.$nextTick();
                    this.modal = this.openModal(this.$refs.editRelationshipModal);
                })
                .finally(() => {
                    this.runningActionForId = null;
                })
        },
        deleteRelationship(relationshipId) {
            this.runningActionForId = relationshipId;
            this.$axios.delete(`/api/relationships/${relationshipId}`)
                .then(() => {
                    this.loading = false;
                    const toastId = this.generateUUID();
                    this.addToast({
                        type: "success",
                        title: "Success",
                        message: "Relationship deleted successfully!",
                        id: toastId,
                    })
                    this.$nextTick(() => {
                        this.toggleToast(toastId);
                    });
                    this.relationships = this.relationships.filter(relationship => relationship.id !== relationshipId);
                })
                .finally(() => {
                    this.runningActionForId = null;
                })
        }
    }
}
