

import moment from "moment";

export default {
    name: "ImportLog",
    filters: {
        labelImport(value) {
            switch (value) {
                case 'ABC_IMPORT':
                    return 'ABC Excel Import';
                case 'ATM_IMPORT':
                    return 'ATM Excel Import';
                case 'CALL_LOG_IMPORT':
                    return 'Call Log Mail Import';
                case 'CALL_LOG_IMPORT_CHECK':
                    return 'Call Log Mail Import Check';
                case 'READERSHIP_IMPORT':
                    return 'Readership Excel Import';
                case 'CLOSED_DEAL_IMPORT':
                    return 'Closed Deal Excel Import';
                case 'RESTRICTED_LIST_IMPORT':
                    return 'Restricted List Excel Import';
                case 'CLIENT_LIST_IMPORT':
                    return 'Client List Excel Import';
                case 'CASH_BURN_IMPORT':
                    return 'Cash Burn Excel Import';
                default:
                    return String(value)
                        .toLocaleLowerCase()
                        .replace(/_/g, ' ')
                        .replace(/\b\w/g, l => l.toUpperCase());
            }
        },
    },
    props: {
        logData: {
            type: Object,
            default: () => {
            }
        }
    },
    methods: {
        displayColor(type, dateTime) {
            if (this.hoursFromNow(dateTime) < this.colorTimeframeHours(type)[0]) {
                return 'success';
            } else if (this.hoursFromNow(dateTime) >= this.colorTimeframeHours(type)[1] || dateTime === null) {
                return 'danger';
            } else {
                return 'warning';
            }
        },
        colorTimeframeHours(type) {
            switch (type) {
                case 'CALL_LOG_IMPORT_CHECK':
                    return [3, 12];
                case 'OLDEST_UPCOMING_EVENT_SESSION_SYNC':
                    return [20, 24];
                default:
                    return [168, 336];
            }
        },
        hoursFromNow(date) {
            if (date === null) {
                return "n/a";
            }
            return moment().diff(date, 'hours');
        },
        fromNow(date) {
            if (date === null) {
                return 'n/a';
            }
            return moment(date).fromNow();
        },
    },
}
