
import modals from "../mixins/modals";
import RestrictedModal from "./restricted-modal.vue";

export default {
    name: "Restricted",
    components: {RestrictedModal},
    mixins: [modals],
    props: {
        companyId: {
            type: Number,
            required: true,
        },
        isRestricted: {
            type: Number,
            required: true,
        },
        small: {
            type: Boolean,
            required: false,
            default: false,
        },
        showModalTrigger: {
            type: String,
            required: false,
            default: null,
        }
    },
    data() {
        return {
            uuid: this.generateUUID(),
            isLoading: false,
            modal: null,
        }
    },
    computed: {
        buttonTitle() {
            switch (this.isRestricted) {
                case 2:
                    return "Is on Restricted List";
                case 1:
                    return "Is on Restricted List (ATM)";
                default:
                    return "";
            }
        }
    },
    watch: {
        showModalTrigger(newValue, oldValue) {
            if (newValue !== oldValue) {
                console.log("The trigger value has changed!", newValue);
                this.openRestrictedModal();
            }
        },
    },
    methods: {
        openRestrictedModal() {
            this.modal = this.openModal(this.$refs.restrictedModal, {backdrop: 'static', keyboard: false});
        },
        closeRestrictedModalAndUpdate() {
            this.closeRestrictedModal();
            this.$emit("updated");
        },
        closeRestrictedModal() {
            this.closeModal(this.modal);
            this.modal = null;
        },
    }
}
