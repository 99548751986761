

import fileDownload from "../../mixins/file-download";
import ResearchReportReadershipTable from "./research-report-readership-table.vue";

export default {
    name: "ResearchReportReadershipModal",
    components: {ResearchReportReadershipTable},
    mixins: [fileDownload],
    props: {
        reportId: {
            type: Number,
            required: true,
        },
        reportTitle: {
            type: String,
            default: "",
        }
    },
    mounted() {
        this.$refs.modalReadership.addEventListener('hidden.bs.modal', this.emitCloseEvent);
    },
    beforeDestroy() {
        this.$refs.modalReadership.removeEventListener('hidden.bs.modal', this.emitCloseEvent);
    },
    methods: {
        emitCloseEvent(event) {
            console.log("Show me the event", event);
            console.log("Show me this", this);
            if (event.target.id === this.$el.id) {
                console.info("It's for me!", {"me": this.$el.id, "target": event.target.id});
                this.$emit('closed', this.item)
            } else {
                console.warn("Not for me!", {"me": this.$el.id, "target": event.target.id});
            }
        },
    }
}
