
import moment from "moment";
import SaveButton from "../save-button.vue";
import LoadingSpinner from "../loading-spinner.vue";

export default {
    name: "EditStaffingModal",
    components: {LoadingSpinner, SaveButton},
    props: {
        id: {
            type: String,
            default: "editStaffingModal"
        },
        item: {
            type: Object,
            default: null
        },
        companyId: {
            type: Number,
            default: null,
        },
        companyName: {
            type: String,
            default: null,
        },
        contactId: {
            type: Number,
            default: null,
        },
        contactName: {
            type: String,
            default: null,
        },
        staffing: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            selectedUser: null,
            selectedRole: null,
            description: "",
            roles: [],
            loading: false,
            loaded: false,
            roleIRI: "",
            roleLoading: false,
            updated: moment().valueOf(),
        }
    },
    computed: {
        updateUrl() {
            return '/api/user_manages_companies';
        },
        isStaffingEmpty() {
            return this.staffing.length === 0;
        },
        name() {
            return this.companyId !== null ? this.companyName : this.contactName;
        }
    },
    watch: {
        item(item) {
            if (item && item.id) {
                this.selectedUser = item.user;
                this.description = item.description;
                if (item.userManagesCompanyRole) {
                    this.roles.push(item.userManagesCompanyRole);
                    this.selectedRole = item.userManagesCompanyRole.id;
                }
                if (item.userManagesContactRole) {
                    this.roles.push(item.userManagesContactRole);
                    this.selectedRole = item.userManagesContactRole.id;
                }
            }
        }
    },
    mounted() {
        this.$refs.modal.addEventListener('shown.bs.modal', () => {
            if (!this.loaded && (!this.item || !this.item.userManagesCompanyRole || !this.item.confirmed)) {
                this.loadRoles();
            } else {
                this.loaded = true;
            }
        })
    },
    methods: {
        loadDefaultRole() {
            const resource = this.companyId > 0 ? 'companies' : 'contacts';

            const currentRole = this.selectedRole;

            this.selectedRole = null;

            if (this.selectedUser) {
                this.roleLoading = true;
                this.$axios.get(`/api/${resource}/default_role_for_user/` + this.selectedUser.id + '?isStaffingEmpty=' + this.isStaffingEmpty)
                    .then((response) => {
                        if (response.data) {
                            this.selectedRole = response.data.id;
                        } else {
                            this.selectedRole = currentRole;
                        }
                    }).finally(() => {
                        this.roleLoading = false;
                    });
            }
        },

        submitForm() {

            const resource = this.companyId > 0 ? 'user_manages_companies' : 'user_manages_contacts';

            this.loading = true;
            console.log("Selected role", this.selectedRole);
            if (!this.item || !this.item.id) {
                this.$axios.post(`/api/${resource}/add`, {
                    roleId: this.selectedRole,
                    contactId: this.contactId,
                    companyId: this.companyId,
                    userId: this.selectedUser.id,
                    description: this.description,
                    confirmed: true
                })
                    .then((response) => {
                        const toastId = this.generateUUID();
                        this.addToast({
                            type: "success",
                            title: "Staffing updated",
                            id: toastId,
                        });

                        this.resetForm();

                        this.$nextTick(() => {
                            this.toggleToast(toastId);
                            this.$emit('staffing-updated');
                        });
                    }).finally(() => {
                        this.loading = false;
                    });
            } else {
                this.$axios.patch(`/api/${resource}/${this.item.id}/update`, {
                    roleId: this.selectedRole,
                    contactId: this.contactId,
                    companyId: this.companyId,
                    userId: this.selectedUser.id,
                    description: this.description,
                    confirmed: true
                }, {
                    headers: {
                        'Content-Type': 'application/merge-patch+json'
                    }
                })
                    .then((response) => {
                        const toastId = this.generateUUID();
                        this.addToast({
                            type: "success",
                            title: "Staffing updated",
                            id: toastId,
                        });

                        this.resetForm();

                        this.$nextTick(() => {
                            this.toggleToast(toastId);
                            this.$emit('staffing-updated');
                        });
                    }).finally(() => {
                        this.loading = false;
                    });
            }

        },
        loadRoles() {
            let url;
            if (this.companyId > 0) {
                url = '/api/staffing/roles/company/' + this.companyId;
            } else {
                url = '/api/staffing/roles/contact/' + this.contactId;
            }

            this.$axios.get(url).then((response) => {
                this.roles = response.data;
            }).finally(() => {
                this.loaded = true;
            });
        },
        resetForm() {
            // Instead of resetting the form just get a new instance
            this.updated = moment().valueOf();

            this.selectedUser = null;
            this.selectedRole = null;
            this.description = "";
            this.loaded = false;
            this.roles = [];
            this.roleIRI = "";

            this.$nextTick(() => {
                this.$emit('staffing-modal-closed')
            })
        }
    }
}
