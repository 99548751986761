
import SearchSelect from "../search-select";

export default {
    name: "InviteUserModal",
    components: {SearchSelect},
    props: {},
    data() {
        return {
            user: null,
        }
    },
    methods: {
        invite() {
            this.$emit("invite", this.user);
        },
    }
}
