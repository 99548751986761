
import staffing from "../../mixins/staffing";
import modals from "../../mixins/modals";
import DncModal from "../company/dnc-modal.vue";
import EditStaffingModal from "./edit-staffing-modal.vue";
import StaffingHistoryModal from "./staffing-history-modal.vue";
import StaffingTable from "./staffing-table.vue";
import optionButtonTooltip from "~/mixins/option-button-tooltip";
import RequestStaffingModal from "~/components/company/request-staffing-modal"
import emailPopoverActionsMixin from "~/mixins/email-popover-actions";


export default {
    name: "StaffingCard",
    components: {
        EditStaffingModal,
        StaffingTable,
        DncModal,
        StaffingHistoryModal,
        RequestStaffingModal,
    },
    mixins: [
        optionButtonTooltip,
        staffing,
        modals,
        emailPopoverActionsMixin
    ],
    props: {
        companyId: {
            type: Number,
            default: null,
        },
        companyName: {
            type: String,
            default: null,
        },
        dnc: {
            type: Boolean,
            default: false
        },
        contactId: {
            type: Number,
            default: null,
        },
        contactName: {
            type: String,
            default: null,
        },
        stacked: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            staffingData: [],
            userManagesCompanies: [],
            loading: false,
            companyToEdit: null,
            loadingItem: null,
            updateTable: null,
            editStaffingKey: this.generateUUID(),
        }
    },
    computed: {
        filters() {
            return {
                companyId: this.companyId,
                contactId: this.contactId,
            }
        },
        mayAddMyself() {
            // User can add themself if "not on marketing list" or "not staffed as banker and has role banker"

            // Not enough information available
            if (!this.userInfo || !this.userInfo.id) return false;

            return true;

            // Allow adding as Banker?
            /* if (this.isUserWithRoleBanker) {
                const isUserStaffedAsBanker = this.userManagesCompanies.filter(
                    (umc) => (umc.user.id === this.userInfo.id && (umc.activeNow || !umc.confirmed) && umc.anyBanker)
                );
                if (isUserStaffedAsBanker.length === 0) {
                    console.log("Adding as Banker is possible.");
                    return true;
                }
            } */

            // Allow adding as Market List Member?
            /* const isUserStaffedAsBanker = this.userManagesCompanies.filter(
                (umc) => (umc.user.id === this.userInfo.id && (umc.activeNow || !umc.confirmed) && umc.marketListMember)
            );
            if (isUserStaffedAsBanker.length === 0) {
                console.log("Adding as MarketListMember is possible.");
                return true;
            } */

            // Default: false.
            // return false;
        },
    },
    watch: {},
    created() {

    },
    methods: {
        openStaffingHistoryModal() {
            this.openModal(this.$refs.staffingHistoryModal);
        },
        updateDnc() {
            this.$axios.get(`/api/companies/${this.companyId}`)
                .then((response) => {
                    this.companyToEdit = response.data;
                    this.$nextTick(() => {
                        this.modal = this.openModal(this.$refs.dncModal);
                    });
                })
        },
        dncUpdated() {
            this.closeModal(this.modal);
            this.$emit('dnc-updated');
        },
        showAddForm() {
            this.modal = this.openModal(this.$refs.editStaffingModal);
        },
        staffingUpdated() {
            this.closeModal(this.modal);
            this.updateTable = new Date();
        },
        resetAddStaffing() {
            this.editStaffingKey = this.generateUUID();
        },
    }
}
