
import PercentageChange from "./helpers/percentage-change.vue";

export default {
    name: "StockInfoSmall",
    components: {PercentageChange},
    props: {
        stockInfo: {
            type: Object,
            required: true,
        }
    }
}
