
import modals from "../mixins/modals";
import {SEVERITY_BLACKLIST, SEVERITY_HIGH, SEVERITY_MEDIUM, SEVERITY_TIERS} from "../mixins/red-flag";
import RedFlagModal from "./red-flag-modal";

export default {
    name: "RedFlag",
    components: {RedFlagModal},
    mixins: [modals],
    props: {
        contactId: {
            type: Number,
            required: false,
            default: null,
        },
        companyId: {
            type: Number,
            required: false,
            default: null,
        },
        initialNumberRedFlags: {
            type: Number,
            required: false,
            default: null,
        },
        initialHighestSeverity: {
            type: Number,
            required: false,
            default: null,
        },
        initialIsFlaggedByCurrentUser: {
            type: Boolean,
            required: false,
            default: null,
        },
        small: {
            type: Boolean,
            required: false,
            default: false,
        },
        showModalTrigger: {
            type: String,
            required: false,
            default: null,
        }
    },
    data() {
        return {
            SEVERITY_TIERS,
            SEVERITY_HIGH,
            SEVERITY_MEDIUM,
            SEVERITY_BLACKLIST,
            uuid: this.generateUUID(),
            numberRedFlags: 0,
            highestSeverity: 0,
            isFlaggedByCurrentUser: false,
            isLoading: false,
            modal: null,
        }
    },
    computed: {
        isForContact() {
            return this.contactId !== null;
        },
        isForCompany() {
            return this.companyId !== null;
        },
        buttonTitle() {
            if (!this.numberRedFlags) {
                return null;
            }

            const severityLabel = SEVERITY_TIERS.find(x => x.value === this.highestSeverity).label;

            if (this.isFlaggedByCurrentUser) {
                if (this.numberRedFlags === 1) {
                    return `${severityLabel} Severity: By you`;
                } else {
                    return `${severityLabel} Severity: By you and ${this.pluralize(this.numberRedFlags - 1, 'other user')}`;
                }

            } else {
                return `${severityLabel} Severity: By ${this.pluralize(this.numberRedFlags, 'other user')}`;
            }
        },
    },
    watch: {
        showModalTrigger(newValue, oldValue) {
            if (newValue !== oldValue) {
                console.log("The trigger value has changed!", newValue);
                this.openRedFlagModal();
            }
        },
    },
    mounted() {
        if (this.initialNumberRedFlags === null || this.initialHighestSeverity === null || this.initialIsFlaggedByCurrentUser === null) {
            this.loadNumberRedFlags();
        } else {
            this.numberRedFlags = this.initialNumberRedFlags;
            this.highestSeverity = this.initialHighestSeverity;
            this.isFlaggedByCurrentUser = this.initialIsFlaggedByCurrentUser;
        }
    },
    methods: {
        loadNumberRedFlags() {
            this.isLoading = true;

            let url;
            if (this.isForContact) {
                url = `/api/user_flags_contacts/for_contact/${this.contactId}`;
            } else if (this.isForCompany) {
                url = `/api/user_flags_companies/for_company/${this.companyId}`;
            } else {
                // eslint-disable-next-line no-throw-literal
                throw "Neither for contact nor for company!";
            }

            this.$axios.get(url).then(response => {
                this.numberRedFlags = response.data.numberRedFlags;
                this.highestSeverity = response.data.highestSeverity;
                this.isFlaggedByCurrentUser = response.data.forCurrentUser;
            }).finally(() => {
                this.isLoading = false;
            });
        },
        openRedFlagModal() {
            this.modal = this.openModal(this.$refs.redFlagModal, {backdrop: 'static', keyboard: false});
        },
        closeAndUpdateModal() {
            this.closeRedFlagModal();

            this.loadNumberRedFlags();
            this.$emit("updated");
        },
        closeRedFlagModal() {
            this.closeModal(this.modal);
            this.modal = null;
        },
    }
}
