
import StaffingTable from "./staffing-table.vue";
import emailPopoverActionsMixin from "~/mixins/email-popover-actions";

export default {
    name: "StaffingHistoryModal",
    components: {StaffingTable},
    mixins:[
        emailPopoverActionsMixin,
    ],
    props: {
        id: {
            type: String,
            default: "staffingHistoryModal"
        },
        companyId: {
            type: Number,
            default: null,
        },
        contactId: {
            type: Number,
            default: null,
        }
    },
    data() {
        return {
            showTable: false,
        }
    },
    computed: {
    },
    watch: {
    },
    mounted() {
        this.$refs.modal.addEventListener('show.bs.modal', () => {
            this.showTable = true;
        });
        this.$refs.modal.addEventListener('hidden.bs.modal', () => {
            this.showTable = false;
        });
    },
    methods: {

    }
}
