

import flatPickr from "vue-flatpickr-component";
import fileDownload from "../mixins/file-download";
import modals from "../mixins/modals";
import StockInfoSmall from "./stock-info-small.vue";
import TableFilters from "./table-filters.vue";
import StreetAlertModal from "./company/street-alert-modal.vue";

export default {
    name: "SaNewsTable",
    components: {StreetAlertModal, TableFilters, StockInfoSmall, flatPickr},
    mixins: [fileDownload, modals],
    props: {
        forSidebar: {
            type: Boolean,
            default: false
        },
        sendClickEvents: {
            type: Boolean,
            default: false
        },
        companyIds: {
            type: Array,
            default: () => []
        },
        showFilters: {
            type: Boolean,
            default: true
        },
        idsToExclude: {
            type: Array,
            default: () => []
        },
        pageSize: {
            type: Number,
            default: 50
        },
        currentUserCompaniesOnly: {
            type: Boolean,
            default: false
        },
        splitUp: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            config: {
                columns: [
                    {
                        headline: "Details",
                        hideHeadlineBreakpoint: "all",
                    },
                    {
                        headline: "Body",
                        breakpoint: "all",
                        align: "start",
                        classes: "render-newline",
                        hidden: this.forSidebar
                    }, {
                        headline: "Link",
                        breakpoint: "all",
                        align: "start",
                        hidden: this.forSidebar
                    },
                ],
                ajaxUrl: "/api/companies/news/list",
                pagination: this.pageSize,
                search: !this.currentUserCompaniesOnly,
                enableRowTabIndex: this.forSidebar,
            },
            selectedCompanies: [],
            selectedCompanyGroups: [],
            companyCharacteristicOptions: [],
            companyCharacteristics: [],
            selectedAnalysts: [],
            selectedBankers: [],
            onlyCoveredCompanies: false,
            onlyStaffedCompanies: false,
            onlyFavoriteCompanies: false,
            currentTableRows: [],
            activeType: 'all',

            searchInput: '',
            dates: null,
            flatpickrConfig: {
                mode: 'range',
                dateFormat: "m/d/Y",
            },
        }
    },

    computed: {
        filters() {
            return {
                companyIds: this.selectedCompanies.map(company => company.id).concat(this.companyIds),
                companyGroupIds: this.selectedCompanyGroups.map(companyGroup => companyGroup.id),
                idsToExclude: this.idsToExclude,
                companyCharacteristics: this.companyCharacteristics,
                onlyCoveredCompanies: this.onlyCoveredCompanies,
                onlyStaffedCompanies: this.onlyStaffedCompanies,
                onlyFavoriteCompanies: this.onlyFavoriteCompanies,
                analysts: this.selectedAnalysts.map(analyst => analyst.id),
                bankers: this.selectedBankers.map(banker => banker.id),
                currentUserCompaniesOnly: this.currentUserCompaniesOnly,
                type: this.activeType,
                dateStart: this.dateStart,
                dateEnd: this.dateEnd,
            }
        },
        dateStart() {
            if (!this.dates || typeof this.dates !== "string" || this.dates.length === 0) {
                return null;
            }

            const [start] = this.dates.split(" to ");
            const date = new Date(start);

            return this.formatLocalDateIso(date);
        },
        dateEnd() {
            if (!this.dates || typeof this.dates !== "string" || this.dates.length === 0) {
                return null;
            }

            const [start, end] = this.dates.split(" to ");
            const date = end ? new Date(end) : new Date(start);

            return this.formatLocalDateIso(date);
        },
    },
    watch: {
        activeType(newVal) {
            this.$emit('activeTypeChanged', newVal);
        }
    },
    created() {
        this.loadCompanyCharacteristics();
        if (this.splitUp) {
            this.activeType = 'specific';
        }
    },
    methods: {
        onRowClicked(row) {
            if (this.sendClickEvents) {
                this.$nuxt.$emit('news-row-clicked', row.data);
            }
        },
        onCellClicked(cell) {
            const rows = this.currentTableRows.filter(row => row.id === cell.newsId);
            if (rows.length === 1) {
                this.onRowClicked(rows[0]);
            }
        },
        loadCompanyCharacteristics() {
            this.$axios.get('/api/company_characteristics/for_select?limit=100')
                .then(response => {
                    this.companyCharacteristicOptions = response.data['hydra:member'];
                })
        },
        onRowsUpdated(data) {
            this.currentTableRows = data.rows;
        },
        downloadNews() {
            const query = {
                filters: this.filters,
                search: this.searchInput
            }
            this.saveQueryAndExport(query, '/api/companies/sa_export');
        },
        formatLocalDateIso(date) {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');

            return `${year}-${month}-${day}`;
        },
        onActiveRowChanged(row, name, index){
            if (this.sendClickEvents && row) {
                this.$emit("active-row-index", index);
                this.$nuxt.$emit('news-row-clicked', row.data);
            }
        },
        async openStreetAlertModal() {
            this.isLoading = true;
            await this.$nextTick();
            this.streetAlertModal = this.openModal(this.$refs.streetAlertModal);
            this.isLoading = false;
        },
    }
}
