
import SkeletonCheckbox from "./skeleton-checkbox";

export default {
    name: "SkeletonTable",
    components: {SkeletonCheckbox},
    props: {
        rows: {
            type: Number,
            default: 10,
        },
        cols: {
            type: Number,
            default: 5,
        },
        lines: {
            type: Number,
            default: 1,
        },
        lineHeight: {
            type: Number,
            default: 10,
        },
        showSelect: {
            type: Boolean,
            default: false,
        },
        pagination: {
            type: Boolean,
            default: true
        }
    }
}
