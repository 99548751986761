

import moment from "moment/moment";

export default {
    name: "NoteReminderForm",
    components: {},
    props: {
        noteId: {
            type: Number,
            default: null,
        },
    },
    data() {
        return {
            number: 1,
            addingNoteReminder: false,
            dateTime: null,
            timezone: 'America/New_York',
            formKey: 'reminderFormKey'
        }
    },
    computed: {
        initialTimezones() {
            return this.userInfo.most_used_timezones.map(tz => {
                return {
                    label: tz,
                    value: tz,
                }
            });
        },
    },
    methods: {
        addNoteReminder() {
            this.formKey = this.generateUUID();
            this.timezone = moment.tz.guess();

            this.$nextTick(() => {
                this.addingNoteReminder = true;
            });
        },
        cancelAddingNoteReminder() {
            this.addingNoteReminder = false;
        },
        addNewNoteReminder() {
            const utcDateTime = moment.tz(this.dateTime, this.timezone).utc().toISOString();
            this.$emit('reminder-added', utcDateTime, this.timezone);
        },
    }
}
