
import moment from "moment";
import HintText from "./hint-text";

export default {
    name: "UploadDropZone",
    components: {HintText},
    props: {
        multiple: {
            type: Boolean,
            default: false
        },
        low: {
            type: Boolean,
            default: false
        },
        acceptedFiles: {
            type: String,
            default: null
        },
        dropzoneDisabled: {
            type: Boolean,
            default: false
        },
        maxFileSize: {
            type: Number,
            default: () => null,
        }
    },
    data() {
        return {
            updated: moment().valueOf(),
        }
    },
    computed: {
        finalMaxFileSize() {
            return this.maxFileSize ? this.maxFileSize : this.maxUploadFileSize;
        }
    },
    methods: {
        onChange() {
            if (this.$refs.uploadFiles) {
                this.$emit('change', this.$refs.uploadFiles.files)
                this.updated = moment().valueOf()
            }
        }
    }
}
