
// import SearchSelect from "../search-select";
// import SearchSelect from "../search-select.vue";
import UploadDropZone from "../upload-drop-zone.vue";
import SaveButton from "../save-button.vue";
import LoadingSpinner from "../loading-spinner.vue";

export default {
    name: "TombstoneModal",
    components: {LoadingSpinner, SaveButton, UploadDropZone}, // {SearchSelect},
    props: {
        transactionId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
            aiGenerating: false,
            authorizationStatus: null,
            logoError: false,
            logoUploaded: false,
            imageSrc: null,
            useOwnLogo: true,
            user: null,
            highlightDropArea: false,
            importFile: "",
            fileUrl: "",
            logoFile: null,
            load: false,

            transaction: null,
            transactionType: null,
            moneyRaised: null,
            hcwRole: null,

            amount: null,
            type: null,
            role: null,
            date: null,

            serializedFile: {
                type: "",
                file: null,
            },

            generatedFile: "",
            companyLogo: "",
            company: null,
            companyId: null,
            companyLogoUrl: null,
            authRequestRunning: false,
            luciteDescription: "",
            editable: true,
        }
    },
    watch: {
        useOwnLogo(newValue) {
            if (newValue) {
                this.resetFile();
            }
        },
        role(newValue) {
            this.hcwRole = newValue;
        },
    },
    mounted() {
        this.$refs.modal.addEventListener('show.bs.modal', () => {
            this.loading = true;
            this.resetAll();
            this.getTombstoneData();
            if (!this.companyLogoUrl || this.fileUrl !== "") {
                this.useOwnLogo = true;
            }
        });
    },
    methods: {
        grantAuthorization() {
            this.authRequestRunning = true
            this.$axios.get("/api/transactions/grant_authorization?transactionId=" + this.transactionId
            ).then(() => {
                this.loadTransaction();
                const toastId = this.generateUUID();
                this.addToast({
                    type: "success",
                    title: "Success",
                    message: "Authorization Granted Successfully.",
                    id: toastId,
                });
                this.$nextTick(() => {
                    this.toggleToast(toastId);
                })
            }).finally(() => {
                this.authRequestRunning = false;
            });
        },
        isEditable() {
            this.editable = !(this.authorizationStatus === 'PENDING' || this.authorizationStatus === 'AUTHORIZED');
        },
        requestAuthorization() {
            this.authRequestRunning = true;
            this.$axios.post('/api/transactions/request_authorization', {
                transactionId: this.transactionId,
                description: this.luciteDescription,
            })
                .then(() => {
                    this.loadTransaction();
                    const toastId = this.generateUUID();
                    this.addToast({
                        type: "success",
                        title: "Success",
                        message: "Authorization Requested Successfully.",
                        id: toastId,
                    });
                    this.$nextTick(() => {
                        this.toggleToast(toastId);
                    })
                })
                .finally(() => {
                    this.authRequestRunning = false;
                    this.isEditable();
                });
        },
        getTombstoneData() {
            this.$axios.get(`/api/transactions/${this.transactionId}?groups[]=transaction:lucite_data`)
                .then((response) => {
                    const luciteData = response.data.luciteData;
                    this.amount = luciteData.amount;
                    this.type = luciteData.type;
                    this.date = luciteData.date;
                    this.role = luciteData.role;
                    this.hcwRole = luciteData.role;
                    this.luciteDescription = luciteData.description;
                    if (luciteData.image !== null) {
                        this.imageSrc = 'data:image/png;base64,' + luciteData.image;
                        this.fileData = luciteData.image;
                    }
                    this.fileUrl = luciteData.logo;
                    if (this.fileUrl) {
                        this.logoUploaded = true;
                        this.serializedFile.file = {
                            logoFile: this.fileUrl,
                            extension: 'png', // Update this if you have the file extension
                        };
                    }
                }).finally(() => {
                    this.loading = false;
                    this.isEditable();
                    this.loadTransaction();

                });

        },

        submitForm() {
            if (this.useOwnLogo && !this.logoUploaded) {
                // Show an error message
                this.logoError = true;
                alert('Please upload a logo.');
                // Stop the form submission
                return;
            }
            this.load = true;
            // Send request to server
            this.$axios.post('/api/transactions/generateTombstone', {
                transactionId: this.transactionId,
                image: this.serializedFile,
                amount: this.amount,
                type: this.type,
                role: this.role,
                date: this.date,
            }).then((response) => {
                // this.generatedFile = response.data;
                // this.generatedFile = response.data.image;
                // this.forceFileDownload(response.data.image);
                // this.imageSrc = window.URL.createObjectURL(new Blob([response.data]));
                this.imageSrc = 'data:image/png;base64,' + response.data.image;
                this.fileData = response.data.image;
                this.getTombstoneData();
            }).finally(() => {
                this.loading = false;
                this.load = false;
            });
        },
        downloadImage() {
            // Convert base64 data to Blob
            const byteCharacters = atob(this.fileData);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], {type: 'image/png'});

            // Create a temporary link element
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = this.transaction.company.name + '_' + this.transactionId + '_' + 'lucite' + '.png';

            // Simulate a click to trigger download
            link.click();
        },
        dragover(event) {
            event.preventDefault();
            this.highlightDropArea = true;
        },
        dragleave(event) {
            // Clean up
            this.highlightDropArea = false;
        },
        drop(event) {
            event.preventDefault();
            this.highlightDropArea = false;

            if (event.dataTransfer.files[0]) {
                this.setFileData(event.dataTransfer.files)
            }
        },
        loadTransaction() {
            this.$axios.get(`/api/transactions/${this.transactionId}`)
                .then(response => {
                    this.transaction = response.data;
                    this.authorizationStatus = response.data.approvalStatus;
                    this.isEditable();
                    if (this.amount === null || this.type === null || this.date === null || this.role === null) {

                        this.moneyRaised = this.transaction.moneyRaised;
                        this.amount = this.nFormatCurrency('USD', this.moneyRaised);
                        this.transactionType = this.transaction.transactionType;
                        this.type = this.transactionType;
                        if (this.hcwRole === null) {
                            this.hcwRole = this.transaction.hcwRole;
                        }
                        if (this.role === null) {
                            this.role = this.transaction.hcwRole;
                        }
                        this.companyId = this.transaction.company.id;
                        this.companyLogoUrl = this.transaction.company.logoUrl;

                        if (this.transaction.pricingDate) {
                            this.date = new Date(this.transaction.pricingDate);
                        } else if (this.transaction.announcementDate) {
                            this.date = new Date(this.transaction.announcementDate);
                        } else {
                            this.date = new Date(this.transaction.filingDate);
                        }
                        this.date = `${this.date.toLocaleString('en-US', {month: 'long'})} ${this.date.getFullYear()}`;
                        console.log(this.companyLogoUrl);
                        if (this.companyLogoUrl && !this.fileUrl) {
                            fetch(this.companyLogoUrl)
                                .then(response => response.blob())
                                .then(blob => {
                                    console.log(blob.type);
                                    if (blob.type !== "text/html") {
                                        // Create a new FileReader object
                                        const reader = new FileReader();

                                        // Set the onload event handler
                                        reader.onload = () => {
                                            // Set the serializedFile property to the Data URL of the logo file
                                            this.serializedFile.file = {
                                                logoFile: reader.result,
                                                extension: blob.type.split('/')[1], // Get the file extension from the MIME type
                                            };
                                        };

                                        // Read the blob as a Data URL
                                        reader.readAsDataURL(blob);

                                        this.useOwnLogo = false;
                                    } else {
                                        this.useOwnLogo = true;
                                    }
                                })
                        } else {
                            this.useOwnLogo = true;
                        }
                    }
                })
        },
        setFileData(files) {
            if (files[0]) {
                this.logoFile = files[0];
                this.fileUrl = URL.createObjectURL(files[0]);

                const reader = new FileReader();
                reader.readAsDataURL(this.logoFile)
                // directory
                reader.onerror = () => {
                    console.log("Error!");
                };
                reader.onload = () => {
                    this.serializedFile.file = {
                        logoFile: reader.result,
                        extension: this.logoFile.name.split('.').pop(),
                    };
                };
                this.logoUploaded = true;
                this.logoError = false;
            }
        },
        resetFile() {
            this.fileUrl = null;
            this.logoFile = null;
            this.logoUploaded = false;
            this.logoError = false;
        },
        resetAll() {
            this.imageSrc = null;
            this.useOwnLogo = false;
            this.user = null;
            this.highlightDropArea = false;
            this.importFile = "";
            this.fileUrl = "";
            this.logoFile = null;

            this.transaction = null;
            this.transactionType = null;
            this.moneyRaised = null;
            this.hcwRole = null;

            this.amount = null;
            this.type = null;
            this.role = null;
            this.date = null;

            this.serializedFile = {
                type: "",
                file: null,
            };

            this.generatedFile = "";
            this.companyLogo = "";
            this.company = null;
            this.companyId = null;
            this.companyLogoUrl = null;
            this.logoUploaded = false;
            this.logoError = false;
        },
        generateText() {
            this.aiGenerating = true;
            this.$axios.post('/api/transactions/generate_description/' + this.transactionId).then((response) => {
                this.luciteDescription = response.data;
            }).finally(() => {
                this.aiGenerating = false
            });
        }
    }
}
